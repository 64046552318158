@import '../variable/color';

.notif-icon {
    height: 1.7rem;
    width: auto;
    color: #1f83bb;
    cursor: pointer;
  }
  .notif-icon:hover {
    color: #3689b9;
  }
  .modal-notif-container{
    width: 27vw;
    background-color: white;
    position: absolute;
    right: 1.3rem;
    top: 4rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 2rem 2rem 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    .header-notif{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0.1rem solid lightgray;
        margin-bottom: 1rem;
        .inner-icon{
            margin-right: 0.5rem;
        }
        p{
            font-weight: 700;
            margin-top: 1rem;
        }
    }
    .list-notif {
      width: 100%;
        height: 60vh;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .container-list{
            width: 100%;
            .item-list{
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding-block: 1rem;
                .icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: aliceblue;
                    margin-right: 1rem;
                    // .succes{
                    //     width: 1.5rem;
                    //     color: green;
                    // }
                    // .procces{
                    //     width: 1.5rem;
                    //     color: #1f83bb;
                    // }
                    // .failed{
                    //     width: 1.5rem;
                    //     color: rgb(207, 58, 58);
                    // }
                }
                .detail{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 ;
                    .title-order{
                        margin: 0;
                        font-size: 0.875rem;
                        font-weight: 700;
                    }
                    .desc-order{
                        font-size: 0.75rem;
                        color: gray;
                        margin: 0;
                    }
                }
                .unread{
                    width: 0.5rem;
                    height: 0.5rem;
                    background-color: red;
                    border-radius: 50%;
                }
    
            }
            .item-list:hover{
                background-color: aliceblue;
            }
        }
        .container-list::-webkit-scrollbar{
        display: none;
        }
    }
}

.notif-dot {
  position: absolute;
  margin-top: -1.7rem;
  margin-left: 0.8rem;
  background-color: $red;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
    .modal-notif-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 0 0 0 0;
        .header-notif {
            .close-modal {
                position: absolute;
                right: 2.5rem;
                font-size: 1.5rem;
            }
        }
    }
}