@import '../../../styles/variable/color';

.modal-date-range {
  .modal-content {
    border-radius: 0.625rem;
    text-align: center;
  }

  .modal-date-range-header {
    h3 {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .modal-date-range-body {
    .date-choice {
      margin-bottom: 1rem;

      .btn-outline-primary {
        font-size: 0.875rem;
        color: $primary3-color;
        background-color: white;
        padding: 0.5rem 1.25rem;

        &.active {
          color: white;
          background-color: $primary3-color;
        }
      }
    }
  }
}