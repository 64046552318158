@import '../variable/color';

.edit-profile{
    height: auto;
    background-color: white;
    border-radius: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    

.header-edit-profile {
  height: 3.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: 0.1rem solid lightgray;
  margin-bottom: 1.2rem;
}
.body-edit-profile {
  height: auto;
  .change-picture {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    .placeholder {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    img {
      width: 7rem;
      height: auto;
      margin-right: 1rem;
      border-radius: 50%;
    }
  }
  .input-fill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .input-wrapper {
      position: relative;
      margin-bottom: 1rem;
      width: 49%;
      .input-text.error {
        border-color: red;
      }
      .input-text {
        width: 100%;
        &:focus {
          outline: none;
          border-color: $primary2-color;
        }
      }
      p {
        color: red;
        position: absolute;
        font-size: 0.875rem;
        margin-top: -0.8rem;
      }
    }

    .input-text {
      padding: 0.6rem;
      box-sizing: border-box;
      border: 1px solid lightgray;
      border-radius: 0.8rem;
      position: relative;
      width: 22rem;
      margin: 1rem auto;
      &#phoneNumber {
        padding-left: 3rem;
      }
    }

    .label-input {
      position: absolute;
      color: grey;
      top: 0.3rem;
      z-index: 1;
      left: 1em;
      font-size: 0.8rem;
      background-color: white;
      padding: 0 5px;
      font-size: 0.8rem;
    }
    .phone-62 {
      position: absolute;
      margin-top: 1.8rem;
      z-index: 99;
      margin-left: 0.8rem;
      border-right: 1px solid black;
      padding-right: 0.7rem;
      // font-size: 0.875rem;
    }
  }
  .footer-edit-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    .btn-submit-profile {
      width: 6rem;
      height: 2rem;
      background-color: #1f83bb;
      border-style: none;
      color: white;
      font-weight: 600;
      border-radius: 0.5rem;
    }
  }
}
}

@media only screen and (max-width: 767px) {
  .edit-profile {
    .body-edit-profile {
      .input-fill {
        .input-wrapper {
          width: 100%;
        }
      }
    }
  }
}