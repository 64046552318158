.change-language {
  height: auto;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  .header {
    height: 3.5rem;
    font-size: 1.1rem;
    font-weight: 700;
    border-bottom: 0.1rem solid lightgray;
    margin-bottom: 1.2rem;
  }
  .language-list {
    padding-left: 1rem;
    input {
      cursor: pointer;
    }
    label {
      font-size: 1rem;
      font-weight: 600;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}
