@import '../../../styles/variable/color';

.import-modal {
  font-family: "Roboto", sans-serif;

  .modal-content {
    width: 28vw;
    margin: auto;
    // margin-top: 20vh;
    padding-inline: 0;
    border-radius: 1rem;
  }

  .import-modal-header {
    padding: 2rem;
    padding-bottom: 0;
    border: none;
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .import-modal-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    padding: 1rem 1.8rem;

    #form-file-upload {
      width: 100%;
      height: 15vh;
      max-width: 100%;
      text-align: center;
      position: relative;
      border-width: 2px;
      border-radius: 0.5rem;
      border-style: solid;
      border-color: #cbd5e1;
      padding-inline: 1rem;
      background-color: $bg-light-grey;
      display: flex;
      justify-content: space-between;
      align-items: center;

      #input-file-upload {
        display: none;
      }

      #label-file-upload {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $bg-light-grey;

        p {
          font-size: 0.875rem;
          margin: 0;
          margin-top: 0.5rem;
        }
      }

      #label-file-upload.drag-active {
        background-color: $bg-light-grey;
        width: 70%;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      #drag-file-element {
        position: absolute;
        width: 70%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }

      .remove-file {
        cursor: pointer;
        color: red;
        margin: 0;
      }
    }

    .download-text {
      margin-top: 1rem;
      font-size: 0.875rem;
      width: 100%;
      text-align: center;

      .download-link {
        text-decoration: none;
        cursor: pointer;
        font-weight: 700;
        color: $primary2-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .import-modal-footer {
    border: none;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 2rem;
    padding-top: 0;

    .apply-file {
      width: 100%;
      font-size: 0.875rem;
      padding-block: 0.5rem;
      border-radius: 0.7rem;
      border: none;
      background-color: $primary2-color;

      &.disable {
        cursor: not-allowed;
        background-color: $primary3-color;
      }
    }
  }
}