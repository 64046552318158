@import '../../../styles/variable/color';
@import '../../../styles/variable/color_v2';

.warning-modal {
  font-family: 'Inter', sans-serif;

  .modal-content {
    width: 25rem;
    margin: auto;
    // margin-top: 23vh;
    padding: 0 1rem;
    border-radius: 1rem;
    background-color: $bg-surface-white;
  }

  .warning-modal-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    text-align: center;

    .modal-text {
      margin-top: 1rem;
      font-size: 1rem;
      width: 100%;
      text-align: center;
    }
  }

  .warning-modal-footer {
    border: none;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 2rem;
    padding-top: 0;
  }
}