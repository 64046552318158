@import '../../../styles/variable/color_v2';

.secondary-button {
  border: 1px solid $primary-800 !important;
  color: $primary-800 !important;
  background-color: $bg-surface-white !important;
  padding: 0;
  padding: 0.5rem 1.5rem;

  &:hover {
    background-color: $gray-100 !important;
  }
}