@import '../../../variable/color';

.order-summary {
  font-family: "Roboto", sans-serif;


  .modal-content {
    border-radius: 1rem;
    padding-inline: 1.5rem;
    padding-block: 1rem;
    width: 30vw;
    height: 90vh;

    .order-summary-header {

      .modal-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }

      h6 {
        margin: 0;
        font-weight: 700;
        font-size: 1.125rem;
      }
    }

    .order-summary-body {
      width: 100%;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      padding: 0;
      margin-block: 1rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .title-body {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .order-info {

        .title {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.4rem;

          h3 {
            font-size: 0.875rem;
          }
        }
      }

      .trip-info {
        .title {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 2rem;
          margin-bottom: 0.5rem;
        }

        .number-trip {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.1rem;
          margin-left: 0.7rem;

          h3 {
            font-size: 0.875rem;
          }
        }
      }

      .additional {
        .title {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.4rem;

          h3 {
            font-size: 0.875rem;

            &:nth-child(2) {
              font-weight: 700;
            }
          }
        }
      }

      .payment-info {
        .title {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.1rem;

          h3 {
            font-size: 0.875rem;
            font-weight: 700;
          }
        }
      }

      .total-price {
        .title {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-top: 0.5rem;

          h3 {
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }

      // .additional-service {
      //   .title {
      //     font-size: 1rem;
      //     font-weight: 700;
      //     margin-top: 2rem;
      //     margin-bottom: 1rem;
      //   }

      //   div {
      //     display: flex;
      //     justify-content: space-between;
      //     margin-bottom: 0.4rem;

      //     h3 {
      //       font-size: 0.875rem;

      //       &:nth-child(2) {
      //         font-weight: 700;
      //       }
      //     }
      //   }
      // }

      // .promo-code {
      //   .title {
      //     font-size: 1rem;
      //     font-weight: 700;
      //     margin-top: 2rem;
      //     margin-bottom: 1rem;
      //   }

      //   .promo-form {
      //     position: relative;
      //     display: flex;
      //     justify-content: space-between;
      //     margin-bottom: 0.4rem;

      //     .promo-icon {
      //       position: absolute;
      //       margin-top: 0.7rem;
      //       margin-left: 0.5rem;
      //     }

      //     .promo-input {
      //       width: 80%;
      //       padding-left: 2.4rem;
      //       background-color: #f7f7f7;
      //       border: none;
      //       border-radius: 0.5rem;
      //     }

      //     .remove-icon {
      //       position: absolute;
      //       margin-top: 0.7rem;
      //       right: 6.5rem;
      //       cursor: pointer;
      //     }

      //     .promo-button {
      //       font-size: 0.875rem;
      //       font-weight: 700;
      //       color: #11567e;
      //       padding: 0.7rem 1.3rem;
      //       border: 1px solid #11567e;

      //       &:hover {
      //         background-color: #11567e;
      //         color: white;
      //       }
      //     }
      //   }

      //   .promo-success {
      //     display: flex;
      //     background-color: rgba(176, 252, 166, 0.438);
      //     align-items: center;
      //     justify-content: center;
      //     gap: 0.3rem;
      //     padding-block: 0.3rem;
      //     border-radius: 0.5rem;

      //     img {
      //       width: 1rem;
      //     }

      //     h4 {
      //       font-size: 0.75rem;
      //       font-weight: 700;
      //       color: $green;
      //       margin: 0;
      //     }
      //   }
      // }

      // .subtotal-info {
      //   margin-bottom: 7rem;
      //   margin-top: 2rem;

      //   div {
      //     display: flex;
      //     justify-content: space-between;
      //     margin-bottom: 0.4rem;

      //     h3 {
      //       font-size: 0.875rem;

      //       &:nth-child(2) {
      //         font-weight: 700;
      //       }
      //     }
      //   }

      //   .promo {
      //     h3 {
      //       color: $green;
      //     }
      //   }
      // }
    }

    .order-summary-footer {
      border-radius: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      padding-inline: 0;
    }
  }

}