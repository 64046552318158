@import './styles/variable/color_v2';

.App {
  font-family: "Roboto", sans-serif;
  letter-spacing: 20% !important;
}

.popup-swal {
  font-family: "Roboto", sans-serif;
  width: 25vw !important;
  border-radius: 1rem !important;
}

.confirm-swal {
  color: white !important;
  background-color: #1f83bb !important;
  padding-block: 0.7rem !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  width: 8rem;
  border-radius: 0.5rem !important;
}

.logout-swal {
  color: white !important;
  background-color: #eb5757 !important;
  padding-block: 0.7rem !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  width: 8rem;
  border-radius: 0.5rem !important;
}

.cancel-swal {
  color: black !important;
  background-color: white !important;
  padding-block: 0.7rem !important;
  font-size: 0.75rem !important;
  font-weight: 700;
  width: 8rem;
  border: 1px solid rgba(0, 0, 0, 0.267) !important;
  border-radius: 0.5rem !important;
}

.title-swal {
  font-size: 1rem;
  color: black;
}

.text-swal {
  font-size: 0.875rem !important;
  color: black;
  padding-inline: 1rem !important;
}

.xendit-button-swal {
  background-color: red;
  padding: 0.8rem 3.6rem;
  border-radius: 0.5rem !important;
  background-color: #1f83bb !important;
}

.confirm-xendit-swal {
  color: white !important;
  background-color: rgba(255, 0, 0, 0) !important;
  // padding-block: 0.7rem !important;
  padding: 0;
  font-weight: 700;
  font-size: 0.75rem !important;
  width: 8rem;
  border-radius: 0.5rem !important;
}

.cancel-xendit-swal {
  color: black !important;
  background-color: white !important;
  padding-block: 0.7rem !important;
  font-size: 0.75rem !important;
  font-weight: 700;
  width: 8rem;
  border: 1px solid rgba(0, 0, 0, 0.267) !important;
  border-radius: 0.5rem !important;
}

.default-container {
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 40px 100px 0px rgba(68, 68, 79, 0.05);
  background-color: $bg-surface-white;
  width: 100%;
}




/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.smooth-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.smooth-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.smooth-scrollbar::-webkit-scrollbar-thumb {
  background: #e1eaf6;
  border-radius: 100px;
}

// Push Notif
.push-notif {
  width: 18vw;

  .push-notif-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 2.3rem;
    }

    .push-notif-title {
      margin: 0;
      width: 60%;
    }

    .push-notif-time {
      font-size: 0.75rem;
      margin: 0;
      font-weight: 700;
    }
  }

  .push-notif-body {
    margin: 0;
    margin-top: 0.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .popup-swal {
    width: 85vw !important;
    border-radius: 1rem !important;
  }

  .confirm-swal {
    color: white !important;
    background-color: #1f83bb !important;
    padding-block: 0.7rem !important;
    font-weight: 700;
    font-size: 0.75rem !important;
    width: 8rem;
    border-radius: 0.5rem !important;
  }

  .logout-swal {
    color: white !important;
    background-color: #eb5757 !important;
    padding-block: 0.7rem !important;
    font-weight: 700;
    font-size: 0.75rem !important;
    width: 8rem;
    border-radius: 0.5rem !important;
  }

  .cancel-swal {
    color: black !important;
    background-color: white !important;
    padding-block: 0.7rem !important;
    font-size: 0.75rem !important;
    font-weight: 700;
    width: 8rem;
    border: 1px solid rgba(0, 0, 0, 0.267) !important;
    border-radius: 0.5rem !important;
  }

  .title-swal {
    font-size: 1rem;
    color: black;
  }

  .text-swal {
    font-size: 0.75rem !important;
    color: black;
    padding-inline: 1rem !important;
  }

  .xendit-button-swal {
    background-color: red;
    padding: 0.8rem 3rem;
    border-radius: 0.5rem !important;
    background-color: #1f83bb !important;
  }

  .confirm-xendit-swal {
    color: white !important;
    background-color: #1f83bb !important;
    padding-block: 0.7rem !important;
    font-weight: 700;
    font-size: 0.75rem !important;
    width: 8rem;
    border-radius: 0.5rem !important;
  }

  .cancel-xendit-swal {
    color: black !important;
    background-color: white !important;
    padding-block: 0.7rem !important;
    font-size: 0.75rem !important;
    font-weight: 700;
    width: 10rem;
    border: 1px solid rgba(0, 0, 0, 0.267) !important;
    border-radius: 0.5rem !important;
  }
}