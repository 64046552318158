#form-file-upload {
  width: 100%;
  height: 15vh;
  max-width: 100%;
  text-align: center;
  position: relative;
  border-width: 2px;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: #cbd5e1;
  padding-inline: 1rem;
  background-color: #F1F5FB;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#form-file-upload #input-file-upload {
  display: none;
}

#form-file-upload #label-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F1F5FB;
}

#form-file-upload #label-file-upload p {
  font-size: 0.875rem;
  margin: 0;
  margin-top: 0.5rem;
}

#form-file-upload #label-file-upload.drag-active {
  background-color: #F1F5FB;
  /* background-color: red; */
  align-items: start;
  text-align: start;
  width: 70%;
}

#form-file-upload #label-file-upload.drag-active p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#form-file-upload #drag-file-element {
  position: absolute;
  width: 70%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#form-file-upload .remove-file {
  cursor: pointer;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.remove-file h4 {
  color: #EB5757;
  margin: 0;
  font-weight: 600;
  font-size: 0.875rem;
}

.remove-file img {
  height: 100%;
}