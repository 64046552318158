@import '../../../styles/variable/color';
@import '../../../styles/variable/color_v2';

.redirectwa-modal {
  font-family: 'Inter', sans-serif;

  .modal-content {
    width: 30rem;
    margin: auto;
    // margin-top: 23vh;
    padding: 0 1rem;
    border-radius: 1rem;
    background-color: $bg-surface-white;
  }

  .redirectwa-modal-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    text-align: center;

    img {
      width: 11rem;
    }

    .modal-title {
      margin-top: 1rem;
      font-size: 1.125rem;
      width: 100%;
      text-align: center;
    }

    .modal-text {
      margin-top: 0.3rem;
      font-size: 0.875rem;
      width: 100%;
      text-align: center;
    }
  }

  .redirectwa-modal-footer {
    border: none;
    margin-top: -1rem;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 2rem;
    padding-top: 0;
  }
}