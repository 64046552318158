@import '../variable/color';

.redirect-app {
  font-family: "Roboto", sans-serif;
  background-color: red;
  margin-left: 0;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 100vw;
    margin-left: 0;
    padding: 0.5rem;
    padding-inline: 0;
    border-radius: 1rem;
  }

  .redirect-app-header {
    padding-inline: 2rem;
    border: none;

    .modal-title {
      width: 100%;
      padding-bottom: 1rem;
    }

    .header-content {
      display: flex;
      width: 1005;
      justify-content: space-between;
      align-items: center;

      div {
        h3 {
          font-size: 1.125rem;
          font-weight: 700;
        }

        h5 {
          font-size: 1rem;
        }
      }
    }
  }

  .redirect-app-body {
    position: relative;
    height: 60vh;
    padding-inline: 0;
    padding-top: 0.5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .redirect-app-banner-img {
      position: relative;
      right: 0;
      width: 95%;
    }

    p {
      font-weight: 500;
    }
  }

  .redirect-app-footer {
    border: none;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 1rem;

    .close {
      width: 40%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $primary2-color;
    }
  }
}

// @media only screen and (max-width: 767px) {
//   .redirect-app {
//     .modal-content {
//       width: 95vw;
//       margin: auto;
//       margin-top: 6vh;
//       padding: 0.5rem;
//       border-radius: 1rem;
//     }
//   }
// }