@import '../../../styles/variable/color';
@import '../../../styles/variable/color_v2';

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.custom-modal {
  font-family: 'Inter', sans-serif;

  .modal-content {
    width: fit-content;
    margin: auto;
    // margin-top: 23vh;
    // text-align: center;
    padding: 1rem 1rem;
    border-radius: 1rem;
    background-color: $bg-surface-white;
  }

  .custom-modal-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    text-align: center;
    // background-color: red;

    .modal-icon {
      width: 6rem;
    }

    .modal-title {
      margin-top: 1rem;
      font-size: 1rem;
      width: 100%;
      text-align: center;
    }

    .modal-text {
      margin-top: 1rem;
      font-size: 1rem;
      width: 100%;
      text-align: center;
    }
  }

  .custom-modal-footer {
    // background-color: blue;
    border: none;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    // padding: 2rem;
    padding-top: 0;
  }
}