@import '../variable/color';

.saved-address{
    height: auto;
    background-color: white;
    border-radius: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    
    .header-saved-address {
        height: 3.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        border-bottom: 0.1rem solid lightgray;
        margin-bottom: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-weight: 700;
            margin: 0;
        }
        .add-address{
            background-color: #1f83bb;
            border-style: none;
            font-size: 0.8rem;
            color: white;
            border-radius: 0.5rem;
            font-weight: bold;
            height: 2.5rem;
            width: 10rem;
        }
        .add-address:hover{
           opacity: .9;
        }
      }
      .body-saved-address{
        height: auto;
        .item-address{
            padding-block: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon-list{
                width: 2rem;
                .list-icon{
                    height: 1.2rem;
                    width: auto;
                    color: $grey2;
                }
            }
            .text-list{
                width: 90%;
                .text-place{
                    margin: 0;
                    font-size: 0.875rem;
                    font-weight: 700;
                }
                .text-street{
                    margin: 0;
                    font-size: 0.8rem;
                    color: #a1b3ce;
                }
                .text-note{
                    margin: 5px 0 0 0 ;
                    font-size: 0.875rem;
                    font-weight: 700;
                }
                .text-detail{
                    margin: 0;
                    font-size: 0.8rem;
                    color: #a1b3ce;
                }
            }
            .btn-container{
                width: 8.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0.5rem;
                .edit-btn{
                    border-style: none;
                    color: $primary2-color;
                    font-size: 0.75rem;
                    font-weight: 700;
                    width: 45%;
                    padding-block: 0.3rem;
                    background-color: $light-grey2;
                    border-radius: 0.5rem;
                }
                .edit-btn:hover{
                    opacity: 0.5;
                }
                .delete-btn{
                    border-style: none;
                    color: #e65c5c;
                    font-size: 0.75rem;
                    font-weight: 600;
                    width: 55%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-block: 0.3rem;
                    border-radius: 0.5rem;
                    background-color: $bg-grey;
                    .icon-delete{
                        margin-right: 0.2rem;
                    }
                }
                .delete-btn:hover{
                    color: #c43f3f;
                }
            }
        }
      }
}
