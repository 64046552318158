.container-title-new {
  width: 100%;
  height: 20rem;
  padding: 7rem 120px 0;
  background-size: cover;
  background-repeat: no-repeat;

  margin-bottom: -7rem;

  .content-title {
    h3 {
      color: white;
    }

    .breadcrumb-item::before {
      color: white;
    }

    .link {
      display: flex;
      list-style-type: none;
      text-decoration: none;
      padding: 0;

      li {
        text-decoration: none;
        margin: 0;

        .link-to {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}