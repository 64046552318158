.image-preview {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  position: relative;
  margin: auto;
  .upload-icon {
    position: absolute;
    background: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    left: 50px;
    top: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 16px;
    &:hover {
      cursor: pointer;
    }
  }
}