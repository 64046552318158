.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  .main-content-delete {
    height: fit-content;
    width: 23rem;
    background-color: white;
    border-radius: 1.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .title {
      font-weight: 700;
      margin-bottom: 0.5rem ;
      margin-top: 1rem;
    }
    .warning{
        margin: 0;
    }
  }
  .btn-container-delete {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    .close-btn {
      height: 3rem;
      width: 50%;
      border-style: none;
      border-radius: 0.5rem;
      margin-right: 1rem;
      color: #1f83bb;
      font-weight: 600;
    }
    .close-btn:hover {
      opacity: 0.5;
    }
    .btn-save {
      height: 3rem;
      width: 50%;
      border-style: none;
      border-radius: 0.5rem;
      color: white;
      font-weight: 600;
      background-color: #eb5757;
    }
    .btn-save:hover {
      opacity: 0.5;
    }
  }
}
