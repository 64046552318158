@import '../../variable/color';

.create-order-manage {
  width: 37vw;
  height: 100vh;
  margin-top: 11rem;
  overflow-y: scroll;
  // scrollbar-width: none;
  // -ms-overflow-style: none;
  z-index: 9999;
  padding: 1rem 1.5rem 1rem;

  // background-color: red;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .service {

    // overflow-y: hidden;
    // transition: all 0.5s linear;
    &.hide {
      display: none;
      // height: none;
      // overflow-y: hidden;
      transition: all 0.5s linear;
    }

    .select-service {
      padding: 1rem;
      width: 100%;
      border: 1px solid $bg-light-grey;
      margin-top: 1rem;
      border-radius: 1rem;

      .title-page {
        text-align: center;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .header-select-service {
        width: 100%;
        height: 2rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text-title {
          font-weight: 700;
          margin-top: 0.8rem;
        }

        .import-btn {
          width: 6rem;
          height: 2rem;
          background-color: #f1f5fb;
          border-style: none;
          border-radius: 0.4rem;
          font-size: 0.75rem;
          font-weight: 700;
        }

        .import-btn:hover {
          background-color: #d9e5f7;
        }
      }

      .form-service {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .sameday-delivery-option {
          width: 12.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          #sameday-delivery {
            display: none;
          }

          .label-radio {
            font-weight: 700;
            cursor: pointer;
            font-size: 0.875rem;

            .icon-sameday-delivery {
              width: 2.5rem;
              margin-right: 0.2rem;
            }

            &.active {
              color: $primary2-color;
            }
          }
        }

        .instant-delivery-option {
          width: 12.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          #instant-delivery {
            display: none;
          }

          .label-radio {
            font-weight: 700;
            cursor: pointer;
            font-size: 0.875rem;

            .icon-instant-delivery {
              width: 2.5rem;
              margin-right: 0.2rem;
            }

            &.active {
              color: $primary2-color;
            }
          }
        }

        .superkul-truck-option {
          width: 12.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          #superkul-truck {
            display: none;
          }

          .label-radio {
            font-weight: 700;
            cursor: pointer;
            font-size: 0.875rem;

            .icon-superkul-truck {
              width: 2.5rem;
              margin-right: 0.2rem;
            }

            &.active {
              color: $primary2-color;
            }
          }
        }

        // .delivery-option {
        //   width: 12.5rem;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   margin-bottom: 1rem;
        //   #delivery-1 {
        //     // display: none;
        //   }
        //   #delivery-2 {
        //     // display: none;
        //   }
        //   #delivery-3 {
        //     // display: none;
        //   }
        //   .label-radio {
        //     font-weight: 700;
        //     cursor: pointer;
        //     font-size: 0.875rem;
        //     .icon-delivery {
        //       width: 2.5rem;
        //       margin-right: 0.2rem;
        //     }
        //     &.active {
        //       color: $primary2-color;
        //     }
        //   }
        // }
        .input-wrapper {
          position: relative;

          img {
            position: absolute;
            background-color: white;
            right: 0.6rem;
            top: 1.7rem;
          }
        }

        .input-text {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 12rem;
          margin: 1rem auto;
          font-weight: 700;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          cursor: pointer;
        }

        .label-input {
          position: absolute;
          color: grey;
          top: 0.4rem;
          z-index: 1;
          left: 1em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }

        select {
          background-color: white;
          font-weight: 700;
          height: 2.7rem;

          option {
            font-weight: 400;
          }
        }
      }
    }

    .select-vehicles {
      padding: 1rem;
      width: 100%;
      border: 1px solid $bg-light-grey;
      margin-top: 1rem;
      border-radius: 1rem;

      .header-vehicles {
        width: 25rem;
        height: 2rem;
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text-title {
          font-weight: 700;
          margin-top: 0.8rem;
        }
      }

      .body-vehicles {
        .vehicles-checkbox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 2rem;
          gap: 1rem;

          .check-vehicles {
            display: none;
          }

          .check-vehicles:checked {
            &+.content {
              .check-icon {
                opacity: 1;
              }

              &+.vehicle-name {
                color: $primary2-color;
              }
            }
          }

          .custom-vehicles {
            width: 15%;
            text-align: center;
            cursor: pointer;

            .content {
              width: 100%;
              text-align: center;
              padding: 0.1rem;
              justify-content: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 0.8rem;

              .icon-vehicles {
                width: 4rem;
              }

              .check-icon {
                margin-top: -0.6rem;
                margin-bottom: 0.2rem;
                opacity: 0;
                // .icon {
                //   display: inline-block;
                //   position: relative;
                //   width: 0.5rem;
                //   height: 0.4rem;
                //   margin-top: -3px;
                //   transform: rotate(-40deg);
                // }
                // .icon::before {
                //   content: "";
                //   width: 3px;
                //   height: 100%;
                //   background-color: #ffffff;
                //   position: absolute;
                //   left: 0;
                //   bottom: 0;
                //   border-radius: 10px;
                //   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.231);
                //   transform: scaleY(0);
                //   transform-origin: top;
                //   transition: all 0.2s ease-in-out;
                // }
                // .icon::after {
                //   content: "";
                //   width: 100%;
                //   height: 3px;
                //   background-color: #ffffff;
                //   position: absolute;
                //   left: 0;
                //   bottom: 0;
                //   border-radius: 10px;
                //   box-shadow: 0 3px 5px rgba(0, 0, 0, 0.231);
                //   transform: scaleX(0);
                //   transform-origin: left;
                //   transition: all 0.2s ease-in-out;
                //   transition-delay: 0.1s;
                // }
              }
            }

            .vehicle-name {
              font-size: 1rem;
            }
          }

          // .custom-vehicles input:checked + .check-btn {
          //   border: 1px solid #1f83bb;
          //   color: #1f83bb;
          //   opacity: 1;
          // }

          // .custom-vehicles input:checked + .check-btn .check-icon {
          //   background-color: #50b044;
          // }

          // .custom-vehicles
          //   input:checked
          //   + .check-btn
          //   .check-icon
          //   .icon::before,
          // .custom-vehicles
          //   input:checked
          //   + .check-btn
          //   .check-icon
          //   .icon::after {
          //   transform: scale(1);
          // }
        }

        .additional-service {
          margin: 2rem 0 1rem 0;

          .max-capacity {
            width: 25rem;
            height: auto;
            padding: 0.1rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1f83bb;
            background-color: #f1f5fb;
            border-radius: 1rem;
            font-size: 0.8rem;
          }

          .additional-title {
            font-weight: 600;
            margin: 0.8rem auto;
          }

          .additional-input {
            display: none;
          }

          .label-additional-list {
            .additional-list {
              width: 25rem;
              padding: 1rem 1rem 0 1rem;
              box-sizing: border-box;
              border-radius: 0.5rem;
              border: 0.1rem solid lightgray;
              display: flex;
              font-size: 0.8rem;
              font-weight: 600;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
            }
          }

          .additional-input:checked+label {
            background-color: #d9e5f7;
            border: 1px solid #1f83bb;
            border-radius: 0.5rem;
          }
        }

        .hiden-addservice {
          text-align: center;
          font-size: 0.8rem;
          margin-top: 1rem;
          color: #1f83bb;
          cursor: pointer;
        }
      }
    }
  }

  .show-hide-service {
    text-align: center;
    font-size: 0.8rem;
    margin-top: 1rem;
    color: $primary2-color;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .show-icon {
      transform: rotate(90deg);
      width: 1.5rem;
    }

    .hide-icon {
      transform: rotate(-90deg);
      width: 1.5rem;
    }
  }

  .input-location {
    padding: 1rem;
    height: auto;
    width: 100%;
    border: 1px solid $bg-light-grey;
    margin-top: 1rem;
    margin-bottom: 25rem;
    border-radius: 1rem;

    .header-input-location {
      width: 100%;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-title {
        font-weight: 700;
        margin-top: 0.8rem;
      }

      .import-choice {
        font-size: 1rem;

        .import-choice-toggle {
          width: 6rem;
          height: 2rem;
          background-color: #f1f5fb;
          color: $black;
          border-style: none;
          border-radius: 0.4rem;
          font-size: 0.75rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .import-choice-menu {
          width: 16rem;
          margin-left: 100%;
          font-size: 0.875rem;

          .import-choice-item {
            width: 100%;
            white-space: inherit;

            &:hover {
              background-color: white;
              color: $primary2-color;
            }
          }
        }
      }

      // .add-btn {
      //   padding: 0.6rem 0.8rem;
      //   background-color: $green;
      //   border-style: none;
      //   border-radius: 0.7rem;
      //   color: white;
      //   font-size: 0.75rem;
      //   font-weight: 600;
      //   .add-btn-icon {
      //     width: 1rem;
      //   }
      // }
      .add-btn:hover {
        background-color: #5fb153;
      }
    }

    .drop-notice {
      font-weight: 400;
      width: 100%;
      margin-top: 1rem;

      &.all-dropped {
        background-color: #51b0443a !important;
        color: $green !important;
      }

      &.not-all-dropped {
        background-color: #1f82bb38 !important;
        color: $primary2-color !important;
      }
    }

    .input-field {
      margin-top: 1rem;

      .input-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $dark-grey;
        // border: 1px solid blue;
        margin-bottom: 1rem;
        position: relative;

        .set-as-pick {
          background-color: $grey1 !important;
          position: absolute;
          color: $black;
          z-index: 99998;

          &+.input-address {
            input {
              padding-left: 3rem;
            }
          }
        }

        .set-as-drop {
          background-color: $orange !important;
          position: absolute;
          color: $black;
          z-index: 99998;

          &+.input-address {
            input {
              padding-left: 3rem;
            }
          }
        }

        .input-address {
          width: 92%;
          height: 2.5rem;

          // height: 2.5rem; conditional input-data-full
          // position: relative;
          input {
            padding-right: 3.5rem;
            // background-color: red;
            font-size: 1rem;
            font-weight: 500;
            border: none;
            width: 100%;
            height: 100%;
            z-index: -1;

            &:focus {
              outline: none;
            }
          }

          .autocomplete-dropdown-container {
            .suggest {
              position: relative;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              background-color: white;
              cursor: pointer;
              z-index: 99997;
              border-top: none !important;
              padding: 0.5rem 0.5rem;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

              span {
                width: 100%;
                height: 100%;
                padding: 0.1rem !important;
                font-size: 0.875rem;
                font-weight: 600;
                font-family: "Roboto", sans-serif !important;
              }

              &:first-child {
                border-top: 1px solid black !important;
              }
            }
          }
        }

        .input-data-full {
          position: absolute;
          padding-inline: 3.2rem 3rem;
          width: 100%;
          // height: 100%;
          background-color: white;
          cursor: pointer;

          &.hidden {
            visibility: hidden;
          }

          .address-label {
            font-size: 0.875rem;
            font-weight: 700;
            margin: 0;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .address-format {
            font-size: 0.75rem;
            font-weight: 400;
            margin: 0;
            width: 90%;
            margin-top: 0.2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $dark-grey2;
          }

          .full-data {
            font-size: 0.75rem;
            font-weight: 400;
            margin: 0;
            margin-top: 0.2rem;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $dark-grey2;
          }
        }

        .recent-loc {
          position: absolute;
          background-color: white;
          width: 100%;
          height: 12rem;
          overflow-y: scroll;
          scrollbar-width: none;
          left: 0;
          top: 3rem;
          z-index: 99997;
          padding: 0.3rem 0.8rem 0;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          h6 {
            font-size: 1rem;
            font-weight: 600;
            color: $dark-grey2;
          }

          .recent-loc-data {
            border-bottom: 1px solid $dark-grey;
            padding-block: 0.5rem;
            width: 80%;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            .address-label {
              font-size: 0.875rem;
              font-weight: 700;
              margin: 0;
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .address-format {
              font-size: 0.75rem;
              font-weight: 400;
              margin: 0;
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .icon {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;

          .search-icon {
            width: 1.5rem;
            margin-right: 0.3rem;
          }

          .saved-address-badge {
            background-color: $light-grey2 !important;
            color: $black !important;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 0.3rem;
            margin-right: 0.8rem;
            cursor: pointer;
          }

          p {
            font-size: 0.75rem;
            margin: 0;
            margin-right: 0.8rem;
            cursor: pointer;
            color: $primary2-color;
            cursor: pointer;
          }

          .saved-address-icon {
            cursor: pointer;
            margin-bottom: 0.1rem;
          }

          .delete-icon {
            font-size: 1.8rem;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }

        // &.dataAddr {
        //   .input-address {
        //     height: 3rem;
        //   }
        // }
        // &.dataFull {
        //   .input-address {
        //     height: 4rem;
        //   }
        // }
      }
    }

    .add-loc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 700;
      font-size: 0.875rem;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);

      &:active {
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1) inset;
      }
    }
  }

  .toggle-icon {
    position: absolute;
    background-color: $primary2-color;
    border-radius: 50%;
    padding: 0.3rem;
    z-index: 99998;
    top: 40vh;
    left: 36vw;
    cursor: pointer;

    &.close {
      transform: rotate(180deg);
    }
  }
}

.detail-create-order-dedi {
  background-color: white;
  position: absolute;
  height: fit-content !important;
  top: 10vh;
  z-index: -2;
  left: 0;
  width: 35vw;
  border-radius: 0 2rem 2rem 0;
  padding: 2rem 2rem 0rem 4vw;
  transition: all 0.5s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  max-height: 90vh;

  // -ms-overflow-style: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  &.show-detail {
    left: 35vw;
    transition: all 0.5s ease;
  }

  .detail-header {
    background-color: white;
    z-index: 99;
    top: 0;
    // left: inherit;
    // width: inherit;
    border-radius: 0 2rem 0 0;

    // padding: 2rem 2rem 1.5rem 10rem;
    h1 {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .set-as-location {
      gap: 0.9rem;
      width: 100%;
      justify-content: space-between;

      .pickup-point {
        width: 50%;
        font-size: 0.75rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.2rem 0.5rem 0.7rem;
        gap: 0.3rem;
        color: #b9b9b9;
        border-color: #b9b9b9;
        border-radius: 0.5rem !important;

        &.active {
          background-color: white;
          color: black;
          border-color: #1f83bb;
        }

        &:hover {
          background-color: white;
        }

        .set-as-pick-icon {
          color: $primary2-color;
        }
      }

      .drop-point {
        width: 50%;
        font-size: 0.75rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.2rem 0.5rem 1.2rem;
        gap: 0.3rem;
        color: #b9b9b9;
        border-color: #b9b9b9;
        border-radius: 0.5rem !important;

        &.active {
          background-color: white;
          color: black;
          border-color: rgb(255, 196, 1);
        }

        &:hover {
          background-color: white;
        }

        .set-as-drop-icon {
          color: $orange;
        }
      }
    }
  }

  .detail-content {
    margin-top: 2rem;
    margin-bottom: 1rem;

    .detail-form {

      // margin-bottom: 10rem;
      .detail-data {
        input {
          &[type='text'] {
            height: 2.5rem;
            margin-bottom: 1.5rem;
            padding-inline: 0.5rem;
            border-radius: 0.5rem;
            border: none;
            border: 1px solid rgba(209, 209, 209, 0.3);
            width: 100%;
            font-size: 0.75rem;

            &.error {
              border-color: red;
            }
          }

          &::placeholder {
            font-size: 0.75rem;
          }

          // &:nth-child(8) {
          //   margin-bottom: 0;
          // }
          &[type='checkbox'] {
            cursor: pointer;
            border-radius: 50%;
            margin-top: 1rem;
            margin-bottom: 2rem;
            margin-right: 0.5rem;
          }

          &#phone-number {
            padding-left: 3rem;
          }
        }

        .range {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          margin-bottom: 2rem;

          .rc-slider-dot {
            opacity: 0;
          }

          .rc-slider-mark-text {
            font-size: 10px;
          }

          h6 {
            text-align: end;
            width: 18%;
            margin: 0;
            font-size: 0.875rem;
          }
        }

        label:not(label.bookmark-address, label#temperature-label) {
          position: absolute;
          font-size: 0.75rem;
          font-weight: 600;
          margin-left: 1rem;
          margin-top: -0.6rem;
          background-color: white;
          padding: 0 5px;
        }

        label {
          &.bookmark-address {
            font-size: 0.875rem;
            font-weight: 600;
          }

          &.bookmark-label {
            font-size: 3rem;
          }
        }

        label#temperature-label {
          font-size: 0.75rem;
          color: $dark-grey2;
          margin-bottom: 0.5rem;
        }

        p {
          color: #bf1650;
          // position: absolute;
          font-size: 0.75rem;
          margin-top: -1.2rem;
          margin-bottom: 1rem;
        }

        .phone-62 {
          position: absolute;
          margin-top: 0.8rem;
          margin-left: 0.7rem;
          border-right: 1px solid black;
          padding-right: 0.7rem;
          font-size: 0.875rem;
        }
      }

      .pickup-packages {
        margin-bottom: 10rem;

        .packages-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2rem;

          h1 {
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
          }

          // button {
          //   background-color: white;
          //   border: none;
          //   color: #50b044;
          //   font-weight: 700;
          //   font-size: 0.875rem;
          //   display: flex;
          //   align-items: center;
          //   gap: 0.2rem;
          // }
        }

        .add-packages-body {
          position: relative;

          .package-category {
            width: 100%;
            gap: 0.5rem;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .package-choices {
              // flex-basis: auto;
              // width: fit-content;
              font-size: 0.75rem;
              font-weight: 600;
              background-color: $light-grey2;
              color: #b9b9b9;
              border-color: white;
              border-radius: 0.5rem !important;

              &.active {
                color: $primary2-color;
                border-color: $primary2-color;
              }

              &:hover {
                border-color: $primary2-color;
              }
            }
          }

          .custom-size-btn {
            font-size: 0.8rem;
            margin-left: auto;
            margin-top: 0.5rem;
            margin-right: 0;
            width: fit-content;
            padding: 0 5px;
            color: $primary2-color;
            cursor: pointer;
            font-weight: 600;
          }

          .package-size {
            width: 100%;
            gap: 0.5rem;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 1.5rem;

            .package-choices {
              // flex-basis: auto;
              // width: fit-content;
              font-size: 0.75rem;
              font-weight: 600;
              background-color: $bg-grey;
              color: $dark-grey2;
              border-color: $bg-light-grey;
              border-radius: 0.5rem !important;

              &.active {
                color: $primary2-color;
                border-color: $primary2-color;
              }

              &:hover {
                border-color: $primary2-color;
              }
            }
          }

          // .search-package {
          //   position: absolute;
          //   right: 3rem;
          //   margin-top: 0.9rem;
          // }
          input {
            &[type='text'] {
              height: 2.5rem;
              margin-bottom: 2rem;
              padding-inline: 0.8rem;
              border-radius: 0.5rem;
              border: none;
              border: 1px solid rgba(209, 209, 209, 0.3);
              width: 100%;
              font-size: 0.75rem;

              &::placeholder {
                font-size: 0.75rem;
                opacity: 0.3;
              }

              &.error {
                border-color: red;
              }
            }

            &#lenght {
              width: 30%;
              margin-right: 0.8rem;
            }

            &#width {
              width: 30%;
              margin-right: 0.8rem;
            }

            &#height {
              width: 30%;
            }

            &#package-quantity {
              margin-bottom: 1rem;
            }

            &::placeholder {
              font-size: 0.875rem;
            }

            &[type='checkbox'] {
              margin-top: 1rem;
              margin-bottom: 2rem;
              margin-right: 0.5rem;
            }

          }

          .range {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.5rem;

            .rc-slider-dot {
              opacity: 0;
            }

            h6 {
              text-align: end;
              width: 18%;
              margin: 0;
              font-size: 0.875rem;
            }
          }

          label:not(label#temperature-label, label.unit, label:first-child, label.label-error) {
            position: absolute;
            font-size: 0.75rem;
            margin-left: 0.9rem;
            margin-top: -0.6rem;
            background-color: white;
            padding: 0 5px;
            color: $dark-grey2;
          }

          label#temperature-label {
            font-size: 0.75rem;
            color: $dark-grey2;
            margin-bottom: 1rem;
          }

          label:first-child {
            font-size: 0.75rem;
            color: $dark-grey2;
            margin-left: 0.9rem;
            margin-bottom: 0.5rem;
          }

          label.unit {
            position: absolute;
            background-color: white;
            margin-left: 3.5rem;
            margin-top: 0.7rem;
            color: $dark-grey2;
            // &.kg {
            //   right: 3rem;
            // }
          }

          p {
            color: #bf1650;
            position: absolute;
            font-size: 0.75rem;
            margin-top: -2rem;
          }

          label.label-error {
            width: 6rem;
            color: #bf1650;
            position: absolute;
            font-size: 0.75rem;
            margin-top: 2.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      // .drop-packages {
      //   .packages-title {
      //     h1 {
      //       margin: 0;
      //       font-size: 1rem;
      //       font-weight: 700;
      //     }
      //   }
      //   .packages-list {
      //     margin-top: 1.5rem;
      //     .pickup-for-drop {
      //       margin-bottom: 1rem;
      //       border-block: 1px solid $light-grey2;
      //       padding-block: 1rem;
      //       h1 {
      //         font-size: 0.875rem;
      //       }
      //       h2 {
      //         font-size: 0.875rem;
      //         font-weight: 600;
      //       }
      //       h3 {
      //         font-size: 0.75rem;
      //         color: #b9b9b9;
      //         margin-bottom: 1rem;
      //       }
      //       .empty-package {
      //         width: 100%;
      //         padding-block: 0.7rem;
      //         font-weight: 400;
      //         background-color: $dark-grey2 !important;
      //       }
      //       .package {
      //         display: flex;
      //         justify-content: space-between;
      //         align-items: center;
      //         border-block: 1px solid $bg-light-grey;
      //         padding: 0.6rem 0rem;
      //         margin-bottom: 1rem;
      //         label {
      //           display: flex;
      //           align-items: center;
      //           justify-content: space-between;
      //           width: 100%;
      //           cursor: pointer;
      //           div {
      //             .package-name {
      //               margin: 0;
      //               font-size: 0.875rem;
      //               font-weight: 700;
      //             }
      //             .package-quantity {
      //               margin: 0;
      //               margin-top: 0.3rem;
      //               font-size: 0.875rem;
      //               font-weight: 400;
      //             }
      //           }
      //           img {
      //             margin-right: -0.15rem;
      //           }
      //         }
      //         // .package-type {
      //         //   .package-name {
      //         //     font-size: 0.875rem;
      //         //     font-weight: 600;
      //         //     margin: 0;
      //         //     margin-bottom: 0.5rem;
      //         //   }
      //         //   .package-quantity {
      //         //     font-size: 0.875rem;
      //         //     margin: 0;
      //         //   }
      //         // }
      //         // .package-button {
      //         //   img {
      //         //     margin-left: 1rem;
      //         //   }
      //         // }
      //       }
      //     }
      //   }
      // }
    }
  }

  .detail-footer {
    display: flex;
    // position: fixed;
    // width: inherit;
    // margin-left:4rem !important;
    // background-color: red;
    justify-content: space-between;
    padding: 1rem 0rem 1.5rem 0rem;
    border-radius: 0 0 2rem 0;
    gap: 1rem;

    // &.fix-bottom {
    //   bottom: 0;
    // }
    .back {
      width: 50%;
      height: 2.5rem;
      font-size: 0.875rem;
      background-color: #e0e0e0;
      color: black;

      &:hover {
        background-color: darken(#e0e0e0, 10%);
      }
    }

    .save {
      background-color: $primary2-color;
      width: 50%;
      font-size: 0.875rem;
    }
  }
}

.add-packages-modal {
  font-family: "Roboto", sans-serif;

  .modal-content {
    width: 27vw;
    margin: auto;
  }

  .add-packages-header {
    padding: 2rem;
    padding-bottom: 0;
    border: none;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  .add-packages-body {
    padding-inline: 2rem;
    position: relative;

    #package-category {
      cursor: pointer;
      margin-bottom: 2rem;
      padding-inline: 0.8rem;
      font-size: 0.875rem;
      height: 3rem;
      width: 100%;
      background-color: white;
      border: none;
      border: 1px;
      border: 1px solid rgba(209, 209, 209, 0.3);
      border-radius: 0.5rem;

      option {
        padding: 5rem;
        height: 5rem;
        margin-top: 5rem;
      }

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    .search-package {
      position: absolute;
      right: 3rem;
      margin-top: 0.9rem;
    }

    input {
      &[type='text'] {
        height: 3rem;
        margin-bottom: 2rem;
        padding-inline: 1rem 2.3rem;
        border-radius: 0.5rem;
        border: none;
        border: 1px solid rgba(209, 209, 209, 0.3);
        width: 100%;
        font-size: 0.875rem;
      }

      &#length {
        width: 30%;
        margin-right: 0.8rem;
      }

      &#width {
        width: 30%;
        margin-right: 0.8rem;
      }

      &#height {
        width: 30%;
      }

      &#package-quantity {
        margin-bottom: 1rem;
      }

      &::placeholder {
        font-size: 0.875rem;
      }

      &[type='checkbox'] {
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-right: 0.5rem;
      }
    }

    .range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      h6 {
        width: 15%;
        margin: 0;
        font-size: 0.875rem;
      }

      .form-range {
        &::-moz-range-thumb {
          background-color: $primary2-color;
          height: 1.3rem;
          width: 1.3rem;
        }

        &::-moz-range-track {
          background-color: $primary3-color;
        }
      }
    }

    label:not(label#temperature-label, label.unit) {
      position: absolute;
      font-size: 0.75rem;
      margin-left: 0.9rem;
      margin-top: -0.6rem;
      background-color: white;
      padding: 0 5px;
      color: $dark-grey2;
    }

    label#temperature-label {
      font-size: 0.75rem;
      color: $dark-grey2;
      margin-bottom: 1rem;
    }

    label.unit {
      position: absolute;
      background-color: white;
      margin-left: 3.5rem;
      margin-top: 0.7rem;
      color: $dark-grey2;
      // &.kg {
      //   right: 3rem;
      // }
    }
  }

  .add-packages-footer {
    border: none;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    padding: 2rem;
    padding-top: 0;

    button {
      width: 50%;
      font-size: 0.875rem;
      padding-block: 0.8rem;
      border-radius: 0.7rem;
      border: none;

      &.cancel {
        background-color: $light-grey2;
        color: $black;
      }

      &.add {
        background-color: $primary2-color;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .create-order-manage {
    margin-top: 10rem;
    padding-top: 0;
    width: 100%;

    .service {
      .select-service {
        .form-service {
          .sameday-delivery-option {
            padding-left: 0;
          }

          .instant-delivery-option {
            padding-left: 0;
          }

          .superkul-truck-option {
            padding-left: 0;
          }

          .input-wrapper {
            position: relative;
            width: 100%;

            img {
              position: absolute;
              background-color: white;
              right: 0.6rem;
              top: 1.7rem;
            }
          }

          .input-text {
            padding: 0.6rem;
            box-sizing: border-box;
            border: 1px solid lightgray;
            border-radius: 0.8rem;
            position: relative;
            width: 100%;
            margin: 1rem auto;
            font-weight: 700;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            cursor: pointer;
          }

          .label-input {
            position: absolute;
            color: grey;
            top: 0.4rem;
            z-index: 1;
            left: 1em;
            background-color: white;
            padding: 0 5px;
            font-size: 0.75rem;
          }

          select {
            background-color: white;
            font-weight: 700;
            height: 2.7rem;

            option {
              font-weight: 400;
            }
          }
        }
      }
    }

    .toggle-icon {
      display: none;
    }

    .detail-create-order-dedi {
      // background-color: red;
      max-height: 100vh;
      top: 0;
      left: -100%;
      margin-left: 0;
      z-index: -2;
      height: 100vh;
      width: 100vw;
      transition: all 0.5s ease;
      border-radius: 0 0 0 0;
      padding: 1rem;

      &.show-detail {
        left: 0;
        margin-left: 0;
        z-index: 100000;
        transition: all 0.5s ease;
      }

      .detail-footer {
        display: flex;
        position: fixed;
        width: inherit;
        left: inherit;
        background-color: white;
        justify-content: space-between;
        padding: 1rem;
        border-radius: 0 0 2rem 0;
        gap: 1rem;
        bottom: 0;

        &.fix-bottom {
          bottom: 0;
        }

        .back {
          width: 50%;
          height: 2.5rem;
          font-size: 0.875rem;
          background-color: #e0e0e0;
          color: black;

          &:hover {
            background-color: darken(#e0e0e0, 10%);
          }
        }

        .save {
          background-color: $primary2-color;
          width: 50%;
          font-size: 0.875rem;
        }
      }
    }
  }
}