@import '../variable/color';

// Custom Swal
.swal-order {
  width: 22vw !important;
  font-size: smaller;
}

.container-payment-swal {
  width: 25vw !important;
  border-radius: 1rem !important;
}

.confirm-payment-swal {
  color: white !important;
  background-color: #1F83BB !important;
  padding-block: 0.7rem !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  width: 8rem;
  border-radius: 0.5rem !important;
}

.cancel-payment-swal {
  color: black !important;
  background-color: white !important;
  padding-block: 0.7rem !important;
  font-size: 0.75rem !important;
  font-weight: 700;
  width: 8rem;
  border: 1px solid rgba(0, 0, 0, 0.267) !important;
  border-radius: 0.5rem !important;
}

.title-payment-swal {
  font-size: 1rem;
  color: black;
}

.text-payment-swal {
  font-size: 0.75rem !important;
  color: black;
  padding-inline: 1rem !important;
}

.swal-order-2 {
  width: 22vw !important;
  font-size: smaller;
  font-weight: 700;
  color: black;
}



.start-delivery {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: flex-start;
  // margin-top: 10rem;

  // Loading Map Screen 
  .loading-map-screen {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
    z-index: 99999;
    position: absolute;
    cursor: wait;
  }

  .order-side {
    height: 100vh;
    position: absolute;
    width: 37vw;
    background-color: #ffffff;
    border-radius: 0 2rem 2rem 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    // z-index: 99999 !important;

    // Header
    .header-order {
      width: inherit;
      position: fixed;
      left: 0;
      top: 0;
      padding-block: 2rem 0;
      padding-inline: 1.5rem;
      z-index: 999;
      background-color: #ffffff;

      .title {
        display: flex;
        justify-content: space-between;

        .home-btn {
          width: 2rem;
          height: 2rem;
          cursor: pointer;
        }

        .title-text {
          width: 100%;
          height: auto;
          text-align: center;
          font-size: 1rem;
          font-weight: 700;
        }
      }

      .header-progress {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        position: relative;

        .active {
          .progress-index {
            background-color: #5cbdf5;
          }

          .progress-name {
            color: #5cbdf5;
          }

          &::before {
            border: 1px solid #5cbdf5
          }
        }

        &>div {
          width: 20%;
          position: relative;
          text-align: center;

          .progress-index {
            background-color: #cecece;
            font-weight: 600;
            width: fit-content;
            margin: 0;
            padding: 0.25rem 0.6rem;
            border-radius: 50%;
            margin-inline: auto;
            color: white;
          }

          .progress-name {
            margin-top: 0.5rem;
            color: #cecece;
            font-size: 1rem;
            font-weight: 700;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }

          &::before {
            content: " ";
            height: 1px;
            width: 7.5rem;
            position: absolute;
            z-index: -1;
            left: -85%;
            top: 15%;
            border: 1px solid #cecece;
          }
        }

        // .create {

        // }
      }
    }

    // Footer
    .footer-order {
      position: fixed;
      width: inherit;
      left: 0;
      bottom: 0;
      background-color: #ececec;
      border-radius: 0;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      z-index: 99998;

      button {
        width: 100%;
        padding-block: 1rem;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.7rem;

        &.btn-primary {
          background-color: $primary2-color !important;
          border: none;
        }

        &.disable {
          background-color: $primary3-color !important;
        }
      }

      &.disable {
        cursor: not-allowed;
      }

      .total-cost-trip {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          margin: 0;
          font-size: 1rem;
          font-weight: 700;
        }

        h4 {
          margin: 0;
          font-size: 1.25rem;
          font-weight: 700;
        }
      }

      .total-cost-order {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h5 {
          margin: 0;
          font-size: 1rem;
          font-weight: 700;
        }

        .payment-method {
          margin-top: 0.8rem;
          background-color: #18608a;
          color: white;
          width: fit-content;
          font-size: 1rem;
          font-weight: 600;
          border-radius: 0.5rem;
          padding: 0.05rem 0.8rem;
        }

        h4 {
          margin: 0;
          font-size: 1.25rem;
          font-weight: 700;
        }
      }

      .confirmation-button {
        display: flex;
        gap: 1rem;
      }
    }
  }

  // ! MAPS
  .maps-side {
    position: relative;
    z-index: -99999 !important;
    width: 100%;
    height: 100vh;

    .navbar-maps {
      width: 100%;
      height: 5rem;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 3rem;
        width: auto;
        margin-left: 30rem;
      }
    }

    .maps-view {
      width: 100%;
      height: 100vh;
      z-index: -99999 !important;
      position: relative;

      .maps {
        width: 100%;
        height: 100vh;
        padding-left: 25vw;
        position: relative;
        z-index: -99999 !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .start-delivery {
    .order-side {
      width: 100vw;
      border-radius: 0 0 0 0;

      .header-order {
        .title {
          .title-text {
            font-size: 0.875rem;
          }
        }

        .header-progress {
          &>div {
            width: 23%;

            .progress-index {
              font-size: 0.75rem;
              padding: 0.25rem 0.5rem;
            }

            .progress-name {
              font-size: 0.75rem;
            }

            &::before {
              width: 4.8rem;
              left: -50%;
            }
          }
        }
      }

      .footer-order {
        border-radius: 0 0 0 0;
      }
    }
  }
}