@import '../variable/color';

.btn-transaction {
  .btn-transaction-header {
    font-size: 1rem;
    margin: 0;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
  }
  .transaction-btn-container{
      position: absolute;
      top: 4.5rem;
      display: flex;
      align-items: center;
      width: 15rem;
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
      ul{
          list-style: none;
          width: 100%;
          border-radius: 1rem;
          margin: 0;
          padding: 0;
          .menu-item{
              display: flex;
              align-items: center;
              width: 11rem;
              cursor: pointer;
              width: 100%;
              a{
                width: 100%;
                font-size: 0.875rem;
                font-weight: 400;
                padding: 1rem;
              }
            &:first-child {
              border-bottom: 1px solid $light-grey2;
              a {
                &:hover {
                  background-color: $bg-grey;
                  border-radius: 1rem 1rem 0 0 ;
                }
              }
            }
            &:last-child {
              a {
                &:hover {
                  background-color: $bg-grey;
                  border-radius: 0 0 1rem 1rem ;
                }
              }
            }
          }
      }
  }
}

@media only screen and (max-width: 767px) {
  .btn-transaction {
    .btn-transaction-header {
      justify-content: flex-start;
    }
    .transaction-btn-container {
      position: relative;
      top: 0.5rem;
      box-shadow: none;
      ul {
        border-radius: 0;
        .menu-item {
          a {
            padding: 0;
            margin: 0.5rem 1rem;
            height: fit-content;
            width: fit-content;
          }
          &:first-child {
            border: none;
          }
        }
      }
    }
  }
}



