@import '../variable/color';

.transaction-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container-title {
    width: 100%;
    height: 20rem;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 7rem 120px 0;

    .content-title {
      h3 {
        color: white;
      }

      .link {
        display: flex;
        list-style-type: none;
        text-decoration: none;
        padding: 0;

        li {
          text-decoration: none;

          .link-to {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }

  .detail-transaction {
    position: relative;
    top: -7rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-inline: 120px;
    gap: 2rem;

    .left-detail {
      width: 70%;
      background-color: white;
      border-radius: 1.5rem;
      padding: 1.5rem 1.5rem 2rem 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

      .header-detail {
        height: 5rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;

        .detail {
          width: 100%;

          .title {
            font-size: 1.125rem;
            font-weight: 700;
          }

          .status-badge {
            font-size: 0.75rem;
            font-weight: 600;
            padding-block: 0.4rem;

            &.schedule {
              background-color: white !important;
              color: $primary2-color;
              border: 1px solid $primary2-color;
            }

            &.purple {
              color: #8115D6;
              border: none;
              background-color: rgba(129, 21, 214, 0.2) !important;
            }

            &.blue {
              color: #129AE7;
              border: none;
              background-color: rgba(18, 153, 231, 0.2) !important;
            }

            &.green {
              color: #15D64B;
              border: none;
              background-color: rgba(21, 214, 76, 0.2) !important;
            }

            &.red {
              color: #ED2013;
              border: none;
              background-color: rgba(237, 33, 19, 0.2) !important;
            }
          }

          .date-order {
            font-size: 0.875rem;
            margin-top: 1rem;

            span {
              font-weight: 700;
            }
          }

          .invoice {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            .invoice-number {
              margin: 0;
              font-size: 0.875rem;

              span {
                font-weight: 700;
              }
            }

            .to-detail {
              text-decoration: none;
              margin: 0;
              font-size: 0.875rem;
            }
          }
        }

        .payment-badge {
          height: fit-content;
          color: white;
          font-size: 0.75rem;
          font-weight: 500;

          &.paid {
            background-color: $green !important;
          }

          &.waiting-payment {
            background-color: $red !important;
          }
        }
      }

      .service-information {
        padding: 0 0 2rem 0;
        border-bottom: 0.1rem solid lightgray;
        margin-top: 4rem;

        .title {
          font-weight: 700;
          font-size: 1rem;
          margin-top: 1.5rem;
        }

        .service-info-content {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          gap: 3rem;

          .service-type {
            width: 25%;

            h4 {
              color: $dark-grey2;
              font-size: 0.75rem;
              font-weight: 600;
              margin-bottom: 0.2rem;
            }

            .desc {
              margin-bottom: 0;
              font-size: 0.875rem;
            }
          }

          .vehicles-type {
            width: 25%;

            h4 {
              color: $dark-grey2;
              font-size: 0.75rem;
              font-weight: 600;
              margin-bottom: 0.2rem;
            }

            .desc {
              margin-bottom: 0;
              font-size: 0.875rem;
            }
          }

          .date-pickup {
            width: 25%;

            h4 {
              color: $dark-grey2;
              font-size: 0.75rem;
              font-weight: 600;
              margin-bottom: 0.2rem;
            }

            .desc {
              margin-bottom: 0;
              font-size: 0.875rem;
            }
          }

          .time-pickup {
            width: 25%;

            h4 {
              color: $dark-grey2;
              font-size: 0.75rem;
              font-weight: 600;
              margin-bottom: 0.2rem;
            }

            .desc {
              margin-bottom: 0;
              font-size: 0.875rem;
            }
          }
        }
      }

      .list-trip {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 2rem;

        .list-item-paid {
          margin-bottom: 1.5rem;
        }

        .list-item {
          margin-bottom: 2rem;
          border-radius: 1rem;
          background-color: $bg-light-grey;
          border: 1px solid $dark-grey;

        }

        .header {
          border-radius: 1rem;

          button {
            display: block;
            box-shadow: none;
            border-radius: 0.5rem;
            box-shadow: 0px 5px 8px 0px $dark-grey;

            &:enabled {
              background-color: white !important;
              color: black;
              // box-shadow: none;
              // border: 2px solid $dark-grey;
              // box-shadow: 0px 5px 8px 0px $dark-grey;
            }

            cursor: default;

            &::after {
              display: none;
            }
          }

          .list-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $dark-grey;
            padding-bottom: 0.8rem;

            .trip-number {
              width: 80%;

              h3 {
                font-size: 0.875rem;
                margin: 0;
              }

              h4 {
                margin: 0;
                font-size: 0.875rem;
                font-weight: 700;
              }
            }

            .price {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              h3 {
                margin: 0;
                font-size: 0.875rem;
                font-weight: 600;
              }
            }
          }

          .list-information {
            margin-top: 1rem;

            .driver {
              margin-bottom: 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;

              .driver-img {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
              }

              div {
                width: 100%;

                .driver-name {
                  font-size: 1rem;
                  font-weight: 700;
                  margin: 0;
                  margin-bottom: 0.3rem;
                }

                .driver-vehicle {
                  font-size: 0.875rem;
                  margin: 0;
                }
              }

              .button {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: flex-end;

                .rate-driver {
                  cursor: pointer;
                  font-size: 0.875rem;
                  font-weight: 600;
                  color: white;
                  background-color: $green !important;
                  border-radius: 0.5rem;
                  padding: 0.3rem 1rem;
                }

                .view-trip {
                  cursor: pointer;
                  font-size: 0.875rem;
                  font-weight: 600;
                  color: $primary2-color;
                  background-color: $dark-grey !important;
                  border-radius: 0.5rem;
                  padding: 0.3rem 1rem;
                }
              }
            }

            .address-name {
              font-size: 1rem;
              font-weight: 700;
              margin: 0;
              margin-bottom: 0.3rem;
            }

            .address-street {
              font-size: 0.75rem;
              margin: 0;
            }

            .status-badge {
              margin-top: 1rem;
              display: flex;
              justify-content: space-between;

              .status {

                // &.waiting-payment {
                //   font-size: 0.75rem;
                //   background-color: $light-grey1 !important;
                //   color: $dark-grey2;
                // }
                // &.done {
                //   background-color: $green !important;
                // }
                // &.test {
                //   color: #F2B900;
                //   background-color: #FFF9ED !important;
                // }
                &.one {
                  color: $dark-grey2;
                  background-color: $light-grey1 !important;
                }

                &.two {
                  color: #319E34;
                  background-color: #EDFFED !important;
                }

                &.three {
                  color: #737373;
                  background-color: #F3F3F3 !important;
                }

                &.four {
                  color: #FFFFFF;
                  background-color: #0C668B !important;
                }

                &.five {
                  color: #FFFFFF;
                  background-color: #56A1CB !important;
                }

                &.six {
                  color: #FFFFFF;
                  background-color: #F2B900 !important;
                }

                &.seven {
                  color: #FFFFFF;
                  background-color: $green !important;
                }

                &.eight {
                  color: #FFFFFF;
                  background-color: #E23A0F !important;
                }
              }

              img {
                width: 1rem;
                background-color: $light-grey1;
                padding: 0.2rem;
                cursor: pointer;

                &.more {
                  transform: rotate(-90deg);
                }

                &.less {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }

        .body {
          border-radius: 1rem;

          .accordion-body {
            padding: 0;
            border-radius: 1rem;
          }

          .list-pickup-drop {
            border: 2px solid $dark-grey;
            border-top: none;
            padding: 1rem;
            background-color: $bg-light-grey;

            &:last-child {
              border-radius: 0 0 0.4rem 0.4rem;
            }

            .title {
              font-size: 0.875rem;
              margin: 0;
            }

            .address-name {
              font-size: 1rem;
              font-weight: 700;
              margin: 0;
              margin-top: 0.5rem;
            }

            .address-street {
              font-size: 0.75rem;
              font-weight: 600;
              margin: 0;
              margin-top: 0.3rem;
            }
          }
        }
      }
    }

    .right-detail {
      width: 30%;
      background-color: white;
      border-radius: 1.5rem;
      padding: 1.5rem 1.5rem 2rem 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

      .header-right {
        background-color: white;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding-bottom: 1rem;

        .title {
          font-size: 1.125rem;
          font-weight: 700;
          margin: 0;
        }

        .status {
          &.unpaid {
            background-color: $red !important;
          }

          &.paid {
            background-color: $primary2-color !important;
          }
        }
      }

      .body-right {
        border-bottom: 1px solid lightgray;
        padding: 1rem 0 0 0;

        .fee {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;

          .title-fee {
            font-size: 0.75rem;
            font-weight: 700;
            margin: 0;
            color: $dark-grey2;
          }

          .price-fee {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 600;
          }

          .discount {
            color: $green;
          }

          // .price-discount {
          //   margin: 0;
          //   color: #51b046;
          // }
        }

        .public-holiday {
          display: flex;
          background-color: rgba(235, 87, 87, 0.6);
          ;
          align-items: center;
          justify-content: center;
          gap: 0.3rem;
          padding-block: 0.3rem;
          padding-inline: 1.5rem;
          border-radius: 0.5rem;

          img {
            width: 1rem;
          }

          h4 {
            text-align: center;
            font-size: 0.75rem;
            line-height: 1.3rem;
            font-weight: 700;
            color: white;
            margin: 0;
          }
        }
      }

      .footer-right {
        .total {
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;

          .total-cost {
            margin: 0;
            font-size: 0.875rem;
          }

          .price-total {
            margin: 0;
            font-size: 0.875rem;
          }
        }

        .button-group {
          display: flex;
          justify-content: space-between;
          gap: 1rem;

          .btn {
            width: 100% !important;
            font-size: 0.875rem;
            font-weight: 700;
            padding-block: 0.5rem;
          }

          .cancel {
            background-color: #ffe8e8;
            color: $red;

            &:hover {
              background-color: darken(#ffe8e8, 5%);
            }
          }

          .pay {
            background-color: $primary2-color;
            color: white;

            &:hover {
              background-color: lighten($primary2-color, 10%);
            }
          }

          .self-pickup {
            background-color: $grey2;
            color: $primary2-color;

            &:hover {
              background-color: darken($grey2, 5%);
            }
          }

          .re-order {
            background-color: $primary2-color;
            color: white;

            &:hover {
              background-color: lighten($primary2-color, 10%);
            }
          }
        }

        .download-invoice {
          width: 18.5rem;
          height: 2.5rem;
          background-color: #51b046;
          color: white;
          border-style: none;
          border-radius: 0.8rem;
          margin-top: 1rem;
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .transaction-detail {
    .container-title {
      padding: 7rem 1rem 0;

      .content-title {
        h3 {
          font-size: 1.25rem;
        }
      }

      .link {
        font-size: 0.875rem;
      }
    }

    .detail-transaction {
      padding-inline: 1rem;
      flex-direction: column;
      justify-content: center;

      .left-detail {
        width: 100%;

        .header-detail {
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;

          .detail {
            .title {
              font-size: 1rem;
            }

            .invoice-number {
              font-size: 0.875rem;
            }
          }

          .payment-badge {
            font-size: 0.625rem;
          }
        }

        .order-information {
          .title {
            margin-top: 3rem;
            font-size: 0.875rem;
          }

          .order-info-content {
            .date-pickup {
              width: 30%;

              .desc {
                font-size: 0.75rem;
              }
            }

            .time-pickup {
              .desc {
                font-size: 0.75rem;
              }
            }

            .total-trips {
              .desc {
                font-size: 0.75rem;
              }
            }
          }
        }

        .service-information {
          .title {
            font-size: 0.875rem;
          }

          .service-info-content {
            flex-wrap: wrap;

            .service-type {
              .desc {
                font-size: 0.75rem;
              }
            }

            .vehicles-type {
              .desc {
                font-size: 0.75rem;
              }
            }
          }
        }

        .list-trip {
          .header {
            .list-information {
              gap: 0.5rem;

              .driver {
                div {
                  width: 100%;

                  .driver-name {
                    font-size: 0.875rem;
                    font-weight: 700;
                    margin: 0;
                    margin-bottom: 0.3rem;
                  }

                  .driver-vehicle {
                    font-size: 0.75rem;
                    margin: 0;
                  }
                }

                .button {
                  .rate-driver {
                    font-size: 0.75rem;
                  }

                  .view-trip {
                    font-size: 0.75rem;
                  }
                }
              }

              .address-name {
                font-size: 0.875rem;
              }
            }
          }

        }
      }

      .right-detail {
        margin: auto;
        width: 90%;

        .header-right {
          &>div {
            .title {
              font-size: 1rem;
            }

            .payment-detail {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}