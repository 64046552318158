@import '../variable/color';

.container-register {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .loading-screen-register {
    width: 100vw;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    top: 0;
    left:0;
    bottom: 0;
    z-index: 99999;
    position: absolute;
    cursor: wait
  }
  .register-bg {
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 25vw 0;
    .text-container {
      margin-bottom: 2rem;
      color: white;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .container-form {
    position: relative;
    top: -5rem;
    width: 48%;
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    .form-header {
      margin-bottom: 2rem;
      &>div {
        display: flex;
        align-items: center;
        gap: 1rem;
        h5 {
          margin: 0;
          margin-bottom: 0.5rem;
          font-weight: 700;
        }
        p {
          font-size: 0.8rem;
          color: gray;
          margin: 0;
        }
      }
      &>h5 {
        margin-top: 1.5rem;
        font-weight: 700;
      }
    }
    .form-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .form-register-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .input-wrapper {
          position: relative;
          margin-bottom: 1rem;
          width: 48%;
          .input-text.error {
            border-color: red;
          }
          .input-text {
            &:focus {
              outline: none;
              border-color: $primary2-color;
            }
          }
          .show-password{
              position: absolute;
              right: 0.5rem;
              top: 1.9rem;
              cursor: pointer;
          }
          p {
            color: #bf1650;
            position: absolute;
            font-size: 0.875rem;
            margin-top: -0.8rem;
          }
          
          p::before {
            display: inline;
          }
        }

        .input-text {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 100%;
          margin: 1rem auto;
          &#phoneNumber {
            padding-left: 3rem;
          }
        }

        .label-input {
          position: absolute;
          color: grey;
          top: 0.3rem;
          z-index: 1;
          left: 2em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }
        .phone-62 {
          position: absolute;
          margin-top: 1.8rem;
          z-index: 99;
          margin-left: 0.8rem;
          border-right: 1px solid black;
          padding-right: 0.7rem;
          // font-size: 0.875rem;
        }
        .container-validation {
          width: 100%;
          margin-top: -1.5rem;
          padding-left: 1rem;
          margin-bottom: 1rem;
          .validator {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            .not-valid {
              color: $red;
              font-size: 0.7rem;
              &+.validator-text {
                color: $red;
              }
            }
            .valid {
              color: $green;
              font-size: 0.875rem;
            }
            .validator-text {
              margin: 0;
              font-size: 0.875rem;
            }
          }
        }
        .container-checkbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .label-checkbox {
            font: 1rem "Fira Sans", sans-serif;
            margin: 0 0 0 0.5rem;
            cursor: pointer;
          }
          #agreement {
            border-radius: 50%;
            cursor: pointer;
          }
        }
        .form-footer {
          // display: flex;
          width: 100%;
          // justify-content: flex-end;
          // align-items: center;
          padding: 3rem 0.5rem 0 0;
          text-align: center;
          .btn-submit-register{
            width: 60%;
            height: 3.125rem;
            background-color: #1f83bb;
            border-style: none;
            margin: 1rem 0 2.813rem 0;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
          }
          h6 {
            margin-top: -1rem;
            margin-bottom: 2rem;
          }
          .btn-google {
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
            padding-block: 0rem;
            width: 60%;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .container-register {
    .register-bg {
      padding-left: 16px;
    }
    .container-form {
      width: 95%;
      top: -10rem;
      .form-body {
        .input-wrapper {
          width: 100% !important;
          .input-text {
            width: 100%;
          }
        }
      }
    }
  }
}


