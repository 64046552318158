@import '../../styles/variable/color_v2';

.sidebar {
  background: #FCFCFC;
  box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.08);
  width: 15%;
  min-height: 100vh;
  max-height: 100vh;
  transition: all 0.5s ease-out;
  margin: 0;
  padding-top: 6rem;
  padding-inline: 0.75rem;
  font-family: 'Inter', sans-serif;

  &.hide {
    margin-left: -16.25rem;
    transition: all 0.5s ease-out;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 1.5rem;
      padding: 0.25rem;
      background-color: white;
      border-radius: 0.5rem;
      transition: all 0.3s linear;

      a {
        color: $text-tertiary;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 1.5rem */
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
      }

      &.active {
        background-color: $bg-surface-gray;
        transition: all 0.3s linear;

        a {
          color: $primary-800;
        }
      }
    }
  }

}