.container-footer {
  height: fit-content;
  width: 100%;
  background-color: #266385;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 2rem 1rem;
  .main-footer {
    width: 75rem;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    .address-superkul {
      height: fit-content;
      width: 20rem;
      background-color: white;
      border-radius: 1rem;
      padding: 1rem;
      img {
        height: 3rem;
        width: auto;
        margin-bottom: 1rem;
      }
      .addres {
        margin-bottom: 1rem;
      }
      p {
        font-size: 0.8rem;
        color: #839abc;
        margin: 0;
      }
    }
    .articles {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 15rem;
      .title-articles {
        color: #6eb9e3;
        font-weight: 600;
      }
      a {
        text-decoration: none;
        color: white;
        margin-bottom: 0.4rem;
      }
    }
    .partners {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 13rem;
      .title-partners {
        color: #6eb9e3;
        font-weight: 600;
      }
      a {
        text-decoration: none;
        color: white;
        margin-bottom: 0.4rem;
      }
    }
    .company {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 13rem;
      .title-company {
        color: #6eb9e3;
        font-weight: 600;
      }
      a {
        text-decoration: none;
        color: white;
        margin-bottom: 0.4rem;
      }
    }
  }
  .copyright {
    border-top: 1px solid lightgrey;
    width: 85%;
    text-align: center;
    color: white;
    padding-top: 1rem;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 767px) {
  .container-footer {
    height: fit-content;
    width: 100vw;
    background-color: #266385;
    display: block;
    .main-footer {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 100%;
      margin-inline: auto;
      gap: 2rem;
      margin-bottom: 0;
      .address-superkul {
        width: 100%;
      }
    }
    .copyright {
      margin-inline: auto;
      font-size: 0.75rem;
    }
  }
}
