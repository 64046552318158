@import '../../variable/color';

.list-data {
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: $bg-grey;
  border: 1px solid $dark-grey;
  display: flex;
  justify-content: space-between;

  .list-left {
    .trip {
      font-size: 0.75rem;
      margin-bottom: 1rem;

      span {
        font-weight: 700;
      }
    }

    .location {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      h4 {
        padding: 0.2rem 0.4rem;
        margin: 0;
        border-radius: 0.4rem;
        font-size: 0.75rem;
        font-weight: 700;
        background-color: #63b6e6;
      }

      h3 {
        margin: 0;
        height: fit-content;
        font-size: 0.875rem;
        font-weight: 700;
      }
    }
  }

  .list-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5rem;

    h6 {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: 700;
    }

    .price {
      font-size: 0.75rem;
      font-weight: 700;
      margin: 0;
    }

    .list-detail {
      border-radius: 0.3rem;
      background-color: #e0e0e0;
      color: #949494;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}