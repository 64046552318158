@import '../variable/color';

.container-request-corporate {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .request-bg {
      width: 100%;
      height: 50vh;
      padding: 7rem 360px 0;
      background-size: cover;
      background-repeat: no-repeat;
      .text-container {
        margin-bottom: 2rem;
        color: white;
        width: 100%;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    .container-form {
      position: relative;
      top: -7rem;
      width: 48%;
      background-color: white;
      border-radius: 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      .form-header {
        margin-bottom: 2rem;
        &>div {
          display: flex;
          align-items: center;
          gap: 1rem;
          h5 {
            margin: 0;
            margin-bottom: 0.5rem;
            font-weight: 700;
          }
          p {
            font-size: 0.8rem;
            color: gray;
            margin: 0;
          }
        }
        &>h5 {
          margin-top: 1.5rem;
          font-weight: 700;
        }
      }
      .form-body {
        h5 {
          margin-top: 3rem;
          font-weight: 700;
        }
        .form-personal-info {
          width: 100%;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          row-gap: 1rem;
          h6 {
            width: fit-content;
            margin: auto;
          }
          .request-image {
            width: 100%;
            margin: auto;
            margin-bottom: 1rem;
          }
          .input-wrapper {
            position: relative;
            width: 48%;
            &.address {
              width: 100%;
            }
            .input-text.error {
              border-color: red;
            }
            .input-text {
              width: 100%;
              padding: 0.6rem;
              box-sizing: border-box;
              border: 1px solid lightgray;
              border-radius: 0.8rem;
              position: relative;
              width: 100%;
              margin: 1rem auto;
              &:focus {
                outline: none;
                border-color: $primary2-color;
              }
            }
            p {
              color: red;
              position: absolute;
              font-size: 0.875rem;
              margin-top: -0.8rem;
            }
            .label-input {
              position: absolute;
              color: grey;
              top: 0.3rem;
              z-index: 1;
              left: 1.5em;
              background-color: white;
              padding: 0 5px;
              font-size: 0.8rem;
            }
            .input-wrapper-address {
              position: relative;
            }
            .input-text-address {
              padding: 0.6rem;
              box-sizing: border-box;
              border: 1px solid lightgray;
              border-radius: 0.8rem;
              position: relative;
              width: 36rem;
              margin: 1rem auto;
            }
            .addl-class__menu {
              z-index: 2;
              border: none !important;
            }
            .select-data {
              margin-top: -2rem;
              margin-bottom: 1rem;
              &>div {
                border-radius: 0.8rem;
                padding: 0.2rem 0;
              }
              .addl-class__control--is-disabled {
                background-color: hsla(0, 0%, 95%, 0.645);
              }
            }
            .select-data-error {
              margin-top: -2rem;
              margin-bottom: 1rem;
              &>div {
                border: 1px solid $red;
                border-radius: 0.8rem;
                padding: 0.2rem 0;
              }
            }
          }
        }
      }
      .form-footer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 3rem 0.5rem 0 0;
        .btn-submit-request {
          width: 8rem;
          background-color: #1f83bb;
          border-style: none;
        }
      }
    }
  }
  
@media only screen and (max-width: 767px) {
  .container-request-corporate {
    .request-bg {
      padding-inline: 16px 0;
    }
    .container-form {
      width: 95%;
      top: -10rem;
      .form-body {
        .form-request-container {
          .input-wrapper {
            width: 100%;
            margin-bottom: 1rem;
            .input-text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}