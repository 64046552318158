@import '../variable/color';

.user-profile {
  display: flex;
  align-items: center;
  margin: 0.2rem 1rem 0 1rem;
  cursor: pointer;

  &>div {
    z-index: -1;
  }

  img {
    width: 4rem;
    height: 4rem;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
}

.modal-user-container {
  width: fit-content;
  min-width: 20rem;
  max-width: 25vw;
  height: fit-content;
  background-color: white;
  position: absolute;
  right: 0.1rem;
  top: 5.1rem;
  border-radius: 1rem;
  padding: 0.5rem 2rem 0.5rem 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  .header-user {
    height: 7rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.1rem solid lightgray;
    margin-bottom: 1.5rem;
    border-bottom: 0.1rem solid lightgray;

    .container-img {
      height: 6rem;
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.1rem;
      border-radius: 50%;

      .avatar-icon {
        width: 5rem;
        height: auto;
        border-radius: 50%;
      }
    }

    .container-text {
      height: 6rem;
      width: fit-content;
      max-width: 70%;
      padding-left: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .username {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .customer-id {
        font-size: 0.8rem;
        color: $dark-grey2;
        margin-bottom: 5px;
      }

      .contact {
        font-size: 0.8rem;
        color: $dark-grey2;
        margin: 0;
      }
    }
  }

  .request-coorporate {
    height: 2.5rem;
    width: 100%;
    background-color: $primary3-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: .5rem;
    border-style: none;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0.3rem;
    text-decoration: none;

    .icon-coorporate {
      color: white;
    }

    .text-coorporate {
      margin: 0;
      font-size: 0.9rem;
      color: white;
    }

    .corporate-badge {
      background-color: $primary2-color !important;
    }
  }

  .list-menu {
    display: flex;
    height: 2.8rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: none;
    border-style: none;
    padding: 0;
    text-decoration: none;
    gap: 1rem;

    .icon-container {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;

      .icon-menu {
        height: 1.5rem;
        width: auto;
      }
    }

    .title-button {
      height: 2rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: gray;
      font-weight: 600;
    }

    .arrow-icon {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icon-arrow {
        height: 1.5rem;
        width: auto;
        color: #B7C7DF;
      }
    }
  }

  .list-menu:hover {
    background-color: aliceblue;
  }

  .list-menu-logout {
    display: flex;
    height: 3rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: none;
    border-top: 1px solid lightgray;
    padding: 0;
    gap: 1rem;

    .icon-container {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;

      .icon-menu {
        height: 1.5rem;
        width: auto;
      }
    }

    .title-button {
      height: 2rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: gray;
      font-weight: 600;
    }

    .arrow-icon {
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .icon-arrow {
        height: 1.5rem;
        width: auto;
        color: #B7C7DF;
      }
    }
  }

  .list-menu-logout:hover {
    background-color: aliceblue;
  }

  .request-coorporate:hover {
    background-color: #299cdf;
  }
}


@media only screen and (max-height: 571px) {
  .modal-user-container {
    height: 85vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    padding-block: 1rem;

    .list-menu-logout {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-height: 611px) {
  .corp {
    height: 85vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    padding-block: 1rem;

    .list-menu-logout {
      margin-bottom: 1rem;
    }
  }
}