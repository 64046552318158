.coming-soon {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  text-align: center;
  .coming-soon-container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h4 {
      font-weight: 700;
    }
    h6 {
      color: #839ABC;
    }
    a {
      .btn {
        background-color: #25D366;
        color: white;
        font-weight: 600;
        font-size: 0.875rem;
        width: 90%;
        padding-block: 0.5rem;
        margin-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon {
    background-position-x: center;
  }
}