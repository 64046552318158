.optimize-info {
  font-family: "Roboto", sans-serif;

  .modal-content {
    width: 28vw;
    margin: auto;
    // margin-top: 20vh;
    padding-inline: 0.8rem;
  }

  .optimize-info-header {
    padding: 2rem;
    padding-bottom: 0;
    border: none;
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .optimize-info-body {
    text-align: center;

    img {
      margin: auto;
      width: fit-content;
      margin-bottom: 1rem;
    }

    p {
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 767px) {
  .optimize-info {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 25vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}