@import '../variable/color';

.navbar {
  background-color: white;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;

  .logo-container {
    width: 20%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      height: 3rem;
      margin-left: 2rem;
    }
  }

  .item-container {
    width: 80%;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .after-login {
      display: flex;
      align-items: center;

      .item-one {
        width: 19rem;
        height: auto;
        border-right: 1px solid lightgray;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        padding-right: 1rem;

        a {
          text-decoration: none;
          color: black;
          font-weight: 600;
        }

        a:hover {
          text-decoration: none;
          color: rgb(41, 40, 40);
        }
      }

      .item-two {
        width: 10rem;
        height: auto;
        border-right: 1px solid lightgray;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .notif-icon {
          height: 1.7rem;
          width: auto;
          color: #1f83bb;
          cursor: pointer;
        }

        .notif-icon:hover {
          color: #3689b9;
        }
      }

      .manage-dedicated {
        margin-left: 1rem;
        display: flex;
        align-items: center;

        .manage-link {
          margin-left: 0.5rem;
          text-decoration: none;
          color: black;
          font-weight: 600;
        }
      }
    }

    .before-login {
      display: flex;

      .btn-login {
        width: 6rem;
        height: 2.5rem;
        background-color: white;
        border: 0.1rem solid #1f83bb;
        border-radius: 0.8rem;
        color: #1f83bb;
        margin-right: 0.5rem;
      }

      .btn-login:hover {
        background-color: aliceblue;
      }

      .btn-register {
        width: 7rem;
        height: 2.5rem;
        background-color: #1f83bb;
        border-style: none;
        border-radius: 0.8rem;
        color: white;
        margin-right: 1rem;
      }

      .btn-register:hover {
        background-color: #3689b9;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    width: 100vw;

    .logo-container {
      width: 50%;

      img {
        width: 60%;
        height: 100%;
      }
    }

    .black-screen {
      position: absolute;

      &.active {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        bottom: 0;
        background-color: black;
        opacity: 0.3;
      }
    }

    .hamburger-container {
      position: absolute;
      background-color: white;
      height: fit-content;
      width: 100vw;
      margin: 0;
      top: -100vh;
      transition: all 1s ease;

      &.active {
        top: 0;
        transition: all 0.5s ease;
      }

      .header-mobile {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 1rem 1.5rem 2rem;

        // background-color: red;
        .logo {
          // background-color: blue;
          width: 35%;
        }

        .close-mobile {
          font-size: 2rem;
          width: 20%;
        }
      }

      .after-login {
        padding-inline: 2rem;
        padding-block: 0rem 2rem;

        .item-one {
          width: fit-content;
          height: auto;
          border: none;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;

          a {
            text-decoration: none;
            color: black;
            font-weight: 600;
          }

          a:hover {
            text-decoration: none;
            color: rgb(41, 40, 40);
          }
        }

        .item-two {
          width: fit-content;
          height: auto;
          border: none;
        }

        .manage-dedicated {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          .manage-link {
            margin-left: 0.3rem;
            text-decoration: none;
            color: black;
          }
        }

        .profile {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          .profile-icon {
            color: $primary2-color;
            width: 1.5rem;
          }

          .profile-link {
            margin-left: 0.3rem;
            text-decoration: none;
            color: black;
          }
        }
      }

      .before-login {
        padding-inline: 2rem;
        padding-block: 1rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .btn-login {
          width: 6rem;
          height: 2.5rem;
          background-color: white;
          border: 0.1rem solid #1f83bb;
          border-radius: 0.8rem;
          color: #1f83bb;
          margin-right: 0.5rem;
        }

        .btn-login:hover {
          background-color: aliceblue;
        }

        .btn-register {
          width: 7rem;
          height: 2.5rem;
          background-color: #1f83bb;
          border-style: none;
          border-radius: 0.8rem;
          color: white;
          margin-right: 1rem;
        }

        .btn-register:hover {
          background-color: #3689b9;
        }
      }
    }
  }
}