@import '../../../styles/variable/color_v2';

.danger-button {
  border: 1px solid $red-100 !important;
  background-color: $bg-surface-white !important;
  color: $red-100 !important;
  padding: 0;
  padding: 0.5rem 1.5rem;
  display: flex !important;
  align-items: center;
  gap: 0.3rem;

  &:hover {
    background-color: $red-100 !important;
    color: $bg-surface-white !important;
  }

  p {
    margin: 0;
    font-weight: 600;
  }

  // &.disabled {
  //   background-color: $primary-800;
  //   opacity: 0.24;
  // }
}