@import '../variable/color';

.realtime-driver {
  font-family: "Roboto", sans-serif;

  .modal-content {
    width: 35vw;
    margin: auto;
    // margin-top: 10vh;
    padding-bottom: 1rem;
    padding-inline: 0.8rem;
  }

  .realtime-driver-header {
    padding: 2rem;
    padding-bottom: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    h1 {
      font-size: 1.25rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .realtime-driver-body {
    padding-inline: 0rem;
    position: relative;

    .body-container {
      background-color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.7rem;
      width: fit-content;
      position: absolute;
      top: 1.7rem;
      left: 0.7rem;
      height: fit-content;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);

      .body-title {
        font-weight: 700;
        font-size: 0.875rem;
      }

      .body-content {
        display: flex;
        justify-content: space-between;

        .info-name {
          width: 100%;

          h6 {
            width: 75%;
            color: $dark-grey2;
            font-size: 0.75rem;
            font-weight: 600;
            margin: 0;
          }

          p {
            width: 25%;
            margin: 0;
            font-weight: 600;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .realtime-driver {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 25vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}