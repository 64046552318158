.service-filter-wrap {
    width: 20rem;
}

.service-btn{
    width: 100%;
    height: 50px;
    background-color: white;
    border: 0.1rem solid lightgray;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    padding: 0 1rem 0 1rem;
    .dropdown-content{
        background-color: white;
        margin-top: 0.1rem;
        height: 9rem;
        width: 16.563rem;
        border-radius: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        .dropdown-item{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}
