@import '../../variable/color';

.trip-planning-manage {
  width: inherit;

  .order-side {
    padding-inline: 1.5rem;
    margin-top: 9rem;
    width: 37vw;
    border-radius: 0 2rem 2rem 0;
    overflow-y: scroll;
    height: 100vh;

    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .service-info {
      .title {
        font-size: 1rem;
        font-weight: 700;
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
      }

      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;

        h3 {
          font-size: 0.875rem;
        }
      }
    }

    .list-trip {
      width: 100%;
      margin-bottom: 20rem;

      .title-trip {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;

        h1 {
          font-size: 1rem;
          font-weight: 700;
          margin: 0;
        }

        .toggle-optimize {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.7rem;

          h1 {
            display: flex;
            gap: 0.3rem;

            .optimize-info {
              cursor: pointer;
              font-size: 0.8rem;
            }
          }
        }
      }

      .list-data-dedicated {
        padding: 1rem 1.2rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
        background-color: $bg-grey;
        border: 1px solid $dark-grey;

        .driver {
          width: 100%;
          margin-bottom: 1rem;
          position: relative;
          display: flex;
          align-items: center;
          gap: 1rem;

          .driver-select {
            border: 1px solid $dark-grey1;
            width: 75%;
            padding-block: 0.5rem;
            padding-inline: 0.7rem 0.5rem;
            font-size: 0.875rem;
            border-radius: 1rem;
            background-color: white;
            cursor: pointer;
            display: flex;
            gap: 0.5rem;
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
            align-items: center;

            .driver-name-display {
              width: fit-content;
              max-width: 60%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 700;
            }
          }

          .driver-list {
            position: absolute;
            width: 75%;
            left: 0;
            top: 2.5rem;
            z-index: 99999;
            background-color: white;
            border: 1px solid $dark-grey1;
            border-radius: 0.5rem;

            .title {
              font-size: 1rem;
              font-weight: 700;
              width: 22vw;
            }

            .driver-list-content {
              border-bottom: 1px solid $dark-grey1;
              padding: 0.5rem;
              border-radius: 0.3rem;

              label {
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-block: 0.5rem;

                .driver-img {
                  width: 3rem;
                  margin-right: 0.7rem;
                  margin-left: 0.7rem;
                  border-radius: 50%;
                }

                .driver-data {
                  display: block;

                  .driver-name {
                    margin: 0;
                    font-size: 0.875rem;
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                  }

                  .driver-bike-number {
                    margin: 0;
                    font-size: 0.875rem;
                  }
                }
              }

              input {
                display: none;
              }

              &:hover {
                background-color: $dark-grey;
              }
            }
          }

          h6 {
            font-size: 0.75rem;
            display: flex;
            align-items: center;
            gap: 0.3rem;
            font-weight: 700;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 0;
          }
        }

        .list-bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          gap: 0.5rem;

          // .location {
          //   display: flex;
          //   align-items: center;
          //   gap: 0.8rem;
          //   h4 {
          //     padding: 0.2rem 0.4rem;
          //     margin: 0;
          //     border-radius: 0.4rem;
          //     font-size: 0.75rem;
          //     font-weight: 700;
          //     background-color: $primary3-color;
          //   }
          //   h3 {
          //     margin: 0;
          //     font-size: 0.875rem;
          //     font-weight: 700;
          //   }
          // }
          // .trip {
          //   font-size: 0.75rem;
          //   margin: 0;
          //   width: 50%;
          //   span {
          //     font-weight: 700;
          //   }
          // }
          // img {
          //   width: 7.5%;
          //   cursor: pointer;
          //   background-color: $bg-light-grey;
          //   padding: 0.2rem;
          // }
          .list-left {
            .trip {
              font-size: 0.75rem;
              margin-bottom: 1rem;

              span {
                font-weight: 700;
              }
            }

            .location {
              display: flex;
              align-items: center;
              gap: 0.8rem;

              h4 {
                padding: 0.2rem 0.4rem;
                margin: 0;
                border-radius: 0.4rem;
                font-size: 0.75rem;
                font-weight: 700;
                background-color: $grey1;
              }

              h3 {
                margin: 0;
                height: fit-content;
                font-size: 0.875rem;
                font-weight: 700;
              }
            }
          }

          .list-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .price {
              font-size: 0.75rem;
              font-weight: 700;
              margin: 0;
            }

            .list-detail {
              border-radius: 0.3rem;
              background-color: #e0e0e0;
              color: #949494;
              font-size: 1.5rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    .additional-service {
      margin-bottom: 18rem;

      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }

      .list-service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.3rem;

        h3 {
          margin: 0;
          font-size: 0.875rem;

          span {
            color: green;
            font-weight: 600;
          }
        }

        .price-service {
          margin: 0;
          font-weight: 700;
        }
      }
    }
  }

  .toggle-icon {
    position: absolute;
    background-color: $primary2-color;
    border-radius: 50%;
    padding: 0.3rem;
    z-index: 99998;
    top: 40vh;
    left: 36vw;
    cursor: pointer;

    &.close {
      transform: rotate(180deg);
    }
  }

  .detail-trip-planning-dedi {
    background-color: white;
    position: absolute;
    height: 100vh;
    top: 0;
    z-index: -2;
    left: 0;
    width: 35vw;
    border-radius: 0 2rem 2rem 0;
    padding: 2rem 2rem 1.5rem 4vw;
    transition: all 0.5s ease;

    &.show-detail {
      left: 35vw;
      transition: all 0.5s ease;
    }

    .detail-header {
      position: fixed;
      width: 35vw;
      left: inherit;
      // padding: 1.5rem 1rem 1rem 3rem;
      border-radius: 0 2rem 0 0;
      row-gap: 1rem;
      background-color: white;
      top: 0;
      z-index: 99;
      padding: 1.5rem 2rem 1.5rem 4vw;

      .trip-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        .trip {
          text-align: center;
          grid-area: trip;
          font-size: 0.75rem;
          background-color: $bg-light-grey;
          padding: 0.2rem 0.2rem;
          border-radius: 0.3rem;

          span {
            font-weight: 700;
          }
        }

        .price {
          font-size: 1rem;
          font-weight: 600;
          grid-area: price;
        }
      }

      .optimize {
        background-color: $bg-light-grey;
        padding: 0.8rem 0.6rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;

        h1 {
          font-size: 1rem;
          font-weight: 700;
          margin: 0;
        }
      }

      .estimation-weights {
        display: flex;

        .estimation {
          font-size: 0.75rem;
          font-weight: 600;
          padding: 0.2rem 0.4rem;
          margin-right: 0.5rem;
          border-radius: 0.3rem;
          color: #949494;
          background-color: $bg-light-grey;
        }

        .weights {
          font-size: 0.75rem;
          font-weight: 600;
          padding: 0.2rem 0.4rem;
          margin-right: 2rem;
          border-radius: 0.3rem;
          color: #949494;
          background-color: $bg-light-grey;
        }
      }
    }

    .detail-data {
      display: flex;
      gap: 0rem;
      margin-top: 4.5rem;
      padding-top: 2rem;
      padding-bottom: 10rem;
      padding-right: 1rem;
      // margin-top: 9.5rem;
      justify-content: space-between;
      overflow: scroll;
      height: 100vh;

      // scrollbar-width: none;
      .detail-point {
        width: 2rem;

        .detail-index {
          position: relative;
          margin-top: 1.9rem;
          margin-bottom: 5rem;

          &.pick {
            h1 {
              background-color: $primary3-color;
            }
          }

          h1 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 700;
            text-align: center;
            border-radius: 50%;
            padding: 0.1rem;
            color: #186491;
            background-color: $orange;
            z-index: 99;
          }

          &::before {
            content: " ";
            height: 270%;
            width: 1px;
            position: absolute;
            left: 50%;
            top: -290%;
            z-index: -1;
            border: 1px dashed #63b6e6;
          }
        }
      }

      .detail-datas {
        width: 88%;
        height: fit-content;
        margin-bottom: 10rem;

        .detail-data-trip {
          width: 100%;

          .trip-point {
            border: 1px solid #e0e0e0;
            border-radius: 1rem;
            padding: 0.6rem 1rem;
            margin-top: -0.2rem;

            .trip-title {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .trip-name {
                font-size: 0.875rem;
                margin: 0;
              }

              h3 {
                font-size: 0.75rem;
                margin: 0;
                color: #63b6e6;
              }
            }

            .loc-name {
              font-size: 0.875rem;
              font-weight: 700;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .loc-address {
              font-size: 0.75rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:first-child {
              .trip-title {
                h3 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .detail-footer {
      display: flex;
      position: fixed;
      width: inherit;
      left: inherit;
      bottom: 0;
      background-color: white;
      justify-content: space-between;
      padding: 1rem 2.5rem 1.5rem 4rem;
      border-radius: 0 0 2rem 0;
      gap: 1rem;

      .back {
        width: 50%;
        height: 2.5rem;
        font-size: 0.875rem;
        background-color: $bg-light-grey;

        &:hover {
          background-color: darken($bg-light-grey, 10%);
        }
      }

      .save {
        width: 50%;
        font-size: 0.875rem;
        color: white;
        background-color: #0086d3;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .trip-planning-manage {
    width: 100vw !important;

    .order-side {
      width: 100vw !important;
      border-radius: 0 0 0 0;

      .list-trip {
        .list-data {
          .driver {
            .driver-list {
              width: 70vw;
            }
          }
        }
      }
    }

    .toggle-icon {
      display: none;
    }

    .detail-trip-planning-dedi {
      top: 0;
      z-index: -2;
      left: -100%;
      width: 100vw !important;
      border-radius: 0 0 0 0;
      padding: 2rem !important;
      transition: all 1s ease;
      z-index: 99998;

      &.show-detail {
        left: 0 !important;
        z-index: 100000 !important;
        transition: all 1s ease;
      }

      .detail-header {
        padding: 2rem !important;
        width: 100vw !important;
      }

      .detail-data {
        margin-top: 10.5rem !important;
      }

      .detail-footer {
        width: 100%;
        left: 0;
        bottom: 0;
        justify-content: space-between;
        padding: 2rem !important;
        border-radius: 0 0 0 0;
        gap: 1rem;
        z-index: 99999;
      }
    }
  }
}