@import '../variable/color';

.ticket-complain-detail {
  font-family: "Roboto", sans-serif;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 30vw;
    margin: auto;
    // margin-top: 6vh;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .ticket-complain-detail-header {
    border: none;

    .modal-title {
      width: 100%;
      border-bottom: 1px solid $light-grey2;
      padding-bottom: 1rem;
    }

    .header-content {
      display: flex;
      width: 1005;
      justify-content: space-between;
      align-items: center;

      div {
        h3 {
          font-size: 1.125rem;
          font-weight: 700;
        }

        h5 {
          font-size: 1rem;
        }
      }

      .waiting {
        background-color: $orange !important;
        color: white;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .done {
        background-color: $green !important;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .onprogress {
        background-color: $primary3-color !important;
        color: white;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .cancel {
        background-color: $red !important;
        color: white;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  .ticket-complain-detail-body {
    position: relative;
    height: 60vh;
    padding-inline: 0;
    padding: 1rem;
    padding-top: 0.5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      font-weight: 500;
    }
  }

  .ticket-complain-detail-footer {
    border: none;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 1rem;

    .close {
      width: 40%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $primary2-color;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ticket-complain-detail {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 6vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}