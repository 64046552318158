@import '../../../../styles/variable/color';
@import '../../../../styles/variable/color_v2';

.edit-sku {
  font-family: 'Inter', sans-serif;

  .modal-content {
    width: 32vw;
    margin: auto;
    // margin-top: 20vh;
    padding: 1rem 1.5rem;
    border-radius: 1rem;

  }

  .edit-sku-header {
    padding: 0.75rem 0;
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .edit-sku-body {
    position: relative;
    height: fit-content;
    padding: 0;
    padding-top: 1rem;

    input {
      &[type='text'] {
        height: 2.5rem;
        margin-bottom: 1.5rem;
        padding-inline: 0.5rem;
        border-radius: 0.5rem;
        border: none;
        border: 1px solid rgba(209, 209, 209, 0.3);
        width: 100%;
        font-size: 0.875rem;

        &.error {
          border-color: red;
        }
      }

      &::placeholder {
        font-size: 0.875rem;
      }

      &[type='checkbox'] {
        cursor: pointer;
        border-radius: 50%;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-right: 0.5rem;
      }
    }

    label {
      font-size: 1rem;
      font-weight: 700;
      background-color: white;
      margin-bottom: 0.3rem;
    }

    p {
      color: #bf1650;
      font-size: 0.75rem;
      margin-top: -1.2rem;
      margin-bottom: 1rem;
    }
  }

  .edit-sku-footer {
    border: none;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 0;
    padding-bottom: 1rem;
  }
}