@import '../../variable/color';

.new-order {
  width: inherit;
  height: 75vh;
  // margin-top: 4rem;
  overflow-y: scroll;
  scrollbar-width: none;
  z-index: 9999;
  padding: 0rem 1.5rem;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .select-service {
    padding: 1rem;
    height: auto;
    width: 100%;
    border: 1px solid $light-grey2;
    // margin-top: 0.8rem;
    border-radius: 1rem;

    .header-select-service {
      width: 100%;
      height: 2rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-title {
        font-weight: 700;
        margin-top: 0.8rem;
      }
    }

    .form-service {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      .date-mode {
        width: 100%;
        background-color: $bg-light-grey;
        border-radius: 0.5rem;

        #daily {
          display: none;
        }

        #monthly {
          display: none;
        }

        .label-radio {
          font-weight: 600;
          cursor: pointer;
          font-size: 0.875rem;
          width: 100%;
          padding: 0.6rem 0.7rem;
          color: $dark-grey2;

          .icon {
            width: 1.1rem;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
          }

          &.active {
            border-radius: 0.5rem;
            border: 1px solid $primary3-color;
          }
        }
      }

      .rent-date {
        position: relative;

        .label-input {
          position: absolute;
          color: grey;
          top: 0.4rem;
          z-index: 1;
          left: 1em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }

        .input-text {
          cursor: pointer;
          font-weight: 600;
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          margin: 1rem auto 0;
          width: 100%;
          opacity: 0.5;
        }

        img {
          position: absolute;
          background-color: white;
          right: 0.6rem;
          top: 1.8rem;
          padding-right: 0.2rem;
          z-index: 99998;
        }
      }

      .services-list {
        width: 100%;
        background-color: $bg-light-grey;
        border-radius: 0.5rem;

        #dedicated-order-include-driver,
        #dedicated-order-exclude-driver,
        #monthly-dedicated,
        #daily-dedicated {
          display: none;
        }

        .label-radio {
          font-weight: 600;
          cursor: pointer;
          font-size: 0.875rem;
          width: 100%;
          padding: 0.6rem 0.7rem;
          color: $dark-grey2;

          .icon {
            width: 1.1rem;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
          }

          &.active {
            border-radius: 0.5rem;
            border: 1px solid $primary3-color;
          }
        }
      }

      // .include-option {
      //   width: 100%;
      //   background-color: $bg-light-grey;
      //   border-radius: 0.5rem;
      //   #include {
      //     display: none;
      //   }
      //   .label-radio {
      //     font-weight: 600;
      //     cursor: pointer;
      //     font-size: 0.875rem;
      //     width: 100%;
      //     padding: 0.6rem 0.7rem;
      //     color: $dark-grey2;
      //     .icon {
      //       width: 1.1rem;
      //       margin-right: 0.5rem;
      //       border-radius: 0.5rem;
      //     }
      //   }
      //   #include:checked + label {
      //     border-radius: 0.5rem;
      //     border: 1px solid $primary3-color;
      //   }
      // }
      // .exclude-option {
      //   width: 100%;
      //   background-color: $bg-light-grey;
      //   border-radius: 0.5rem;
      //   #exclude {
      //     display: none;
      //   }
      //   .label-radio {
      //     font-weight: 600;
      //     cursor: pointer;
      //     font-size: 0.875rem;
      //     width: 100%;
      //     padding: 0.6rem 0.7rem;
      //     color: $dark-grey2;
      //     .icon {
      //       width: 1.1rem;
      //       margin-right: 0.5rem;
      //       border-radius: 0.5rem;
      //     }
      //   }
      //   #exclude:checked + label {
      //     border-radius: 0.5rem;
      //     border: 1px solid $primary3-color;
      //   }
      // }
    }
  }

  .vehicle-address {
    padding: 1rem;
    border: 1px solid $light-grey2;
    border-radius: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .select-vehicles {
      height: auto;
      width: 100%;

      .header-vehicles {
        width: 25rem;
        height: 2rem;
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text-title {
          font-weight: 700;
          margin-top: 0.8rem;
        }
      }

      .body-vehicles {
        .vehicles-checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          gap: 1rem;

          .check-vehicles {
            display: none;
          }

          .check-vehicles:checked {
            &+.content {
              .check-icon {
                opacity: 1;
              }

              &+.vehicle-name {
                color: $primary2-color;
              }
            }
          }

          .custom-vehicles {
            width: 25%;
            text-align: center;
            cursor: pointer;

            .content {
              width: 100%;
              text-align: center;
              padding: 0.1rem;
              justify-content: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 0.8rem;

              .icon-vehicles {
                width: 4rem;
              }

              .check-icon {
                margin-top: -0.6rem;
                margin-bottom: 0.2rem;
                opacity: 0;
                // .icon {
                //   display: inline-block;
                //   position: relative;
                //   width: 0.5rem;
                //   height: 0.4rem;
                //   margin-top: -3px;
                //   transform: rotate(-40deg);
                // }
                // .icon::before {
                //   content: "";
                //   width: 3px;
                //   height: 100%;
                //   background-color: #ffffff;
                //   position: absolute;
                //   left: 0;
                //   bottom: 0;
                //   border-radius: 10px;
                //   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.231);
                //   transform: scaleY(0);
                //   transform-origin: top;
                //   transition: all 0.2s ease-in-out;
                // }
                // .icon::after {
                //   content: "";
                //   width: 100%;
                //   height: 3px;
                //   background-color: #ffffff;
                //   position: absolute;
                //   left: 0;
                //   bottom: 0;
                //   border-radius: 10px;
                //   box-shadow: 0 3px 5px rgba(0, 0, 0, 0.231);
                //   transform: scaleX(0);
                //   transform-origin: left;
                //   transition: all 0.2s ease-in-out;
                //   transition-delay: 0.1s;
                // }
              }
            }

            .vehicle-name {
              font-size: 1rem;
            }
          }

          // .custom-vehicles input:checked + .check-btn {
          //   border: 1px solid #1f83bb;
          //   color: #1f83bb;
          //   opacity: 1;
          // }

          // .custom-vehicles input:checked + .check-btn .check-icon {
          //   background-color: #50b044;
          // }

          // .custom-vehicles
          //   input:checked
          //   + .check-btn
          //   .check-icon
          //   .icon::before,
          // .custom-vehicles
          //   input:checked
          //   + .check-btn
          //   .check-icon
          //   .icon::after {
          //   transform: scale(1);
          // }
        }

        .vehicle-quantity {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h1 {
            font-size: 0.875rem;
            font-weight: 600;
            margin: 0;
          }

          .quantity {
            border-radius: 0.5rem;
            gap: 0.5rem;
            border: 1px solid $grey2;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              cursor: pointer;
              padding: 0.7rem 1rem;
            }
          }
        }

        .additional-service {
          margin: 2rem 0 1rem 0;

          .max-capacity {
            width: 25rem;
            height: auto;
            padding: 0.1rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1f83bb;
            background-color: #f1f5fb;
            border-radius: 1rem;
            font-size: 0.8rem;
          }

          .additional-title {
            font-weight: 600;
            margin: 0.8rem auto;
          }

          .additional-input {
            display: none;
          }

          .label-additional-list {
            .additional-list {
              width: 25rem;
              padding: 1rem 1rem 0 1rem;
              box-sizing: border-box;
              border-radius: 0.5rem;
              border: 0.1rem solid lightgray;
              display: flex;
              font-size: 0.8rem;
              font-weight: 600;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
            }
          }

          .additional-input:checked+label {
            background-color: #d9e5f7;
            border: 1px solid #1f83bb;
            border-radius: 0.5rem;
          }
        }
      }
    }

    .pool-address {
      margin-top: 2rem;

      .pool-title {
        font-size: 1rem;
        font-weight: 700;
      }

      .rent-date {
        position: relative;

        .label-input {
          position: absolute;
          color: grey;
          top: 0.4rem;
          z-index: 1;
          left: 1em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
          z-index: 99998;
        }

        .input-text {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          margin: 1rem auto;
          width: 100%;
          opacity: 0.5;
          z-index: 0;
          color: black !important;
          font-weight: 500;
          // &:focus {
          //   outline: none;
          //   border-color: $primary2-color;
          // }
          // &.error {
          //   border-color: red;
          // }
        }

        // p {
        //   color: red;
        //   position: absolute;
        //   font-size: 0.875rem;
        //   margin-top: -0.8rem;
        // }
      }
    }
  }
}