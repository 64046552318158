@import '../variable/color';

.login-page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .register-bg {
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 25vw 0;
    .text-container {
      margin-bottom: 2rem;
      color: white;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .login-page-content {
    width: fit-content;
    margin: auto;
    margin-top: -10rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    border-radius: 1rem;
    .header-login-page{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1rem;
      h4{
          font-weight: 800;
      }
      p{
          font-size: 1rem;
          color: #3598d1;
      }
    }
    .login-page-form{
      display: flex;
      flex-direction: column;
      align-items: center;
      .input-wrapper {
          position: relative;
          width: 100%;
          .show-password{
          position: absolute;
          right: 0.5rem;
          top: 1.9rem;
          cursor: pointer;
          }
      }

      .input-text.error {
        border-color: red;
      }
      
      .input-text {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 100%;
          margin: 1rem auto;
          &:focus {
            outline: none;
            border-color: $primary2-color;
          }
      }
      p {
        color: red;
        position: absolute;
        font-size: 0.875rem;
        margin-top: -0.8rem;
      }

      #phoneNumber {
        padding-left: 3.5rem;
      }
      
      .label-input {
          position: absolute;
          color: grey;
          top: 0.3rem;
          z-index: 1;
          left: 2em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
      }
      .forgot-password{
          position: relative;
          right: -6.6rem;
          text-decoration: none;
          color: black;
      }
      .btn-submit-login{
        width: 20.938rem;
        height: 3.125rem;
        background-color: #1f83bb;
        border-style: none;
        margin: 2.813rem 0 2.813rem 0;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
      }
      .phone-62 {
        position: absolute;
        margin-top: 1.8rem;
        z-index: 99;
        margin-left: 0.8rem;
        border-right: 1px solid black;
        padding-right: 0.7rem;
        // font-size: 0.875rem;
      }
      h6 {
        margin-top: -2rem;
        margin-bottom: 1rem;
      }
      .btn-google {
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
        padding-block: 0rem;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
  .signup{
    color: gray;
    text-align: center;
    a {
      text-decoration: none;
      color: #1f83bb;
      font-weight: 600;
    }
  } 
}


@media only screen and (max-width: 767px) {
    // .modalContainer {
    // flex-direction: column;
    // top: 0;
    // left: 0;
    // transform: none;
    // width: 100vw;
    // height: 100vh;
    // border-radius: 0 0 0 0;
    // .closeBtn {
    //     font-size: 1.5rem;
    // }
    // }
}
    