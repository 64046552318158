@import '../variable/color';

.ticket-complain {
  height: auto;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  .header-ticket {
    height: 3.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 0.1rem solid lightgray;
    margin-bottom: 1.2rem;
  }
  .list-ticket {
    cursor: pointer;
    height: 5rem;
    display: flex;
    align-items: center;
    .container-icon-ticket {
      height: 3rem;
      width: 3rem;
      background-color: #f0f4fa;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40%;
      margin-right: 1rem;
      .icon-ticket {
        width: 2rem;
        height: auto;
        color: #6eb8e2;
      }
    }
    .container-detail-ticket {
      width: 77%;
      .date-ticket {
        font-size: 0.8rem;
        color: #a2b5ce;
        margin: 0;
        font-weight: 600;
      }
      .name-ticket {
        font-weight: 600;
        margin: 0;
      }
    }
    .container-status-ticket{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 7rem;
        .waiting {
          height: 1.5rem;
          width: auto;
          background-color: $orange;
          padding: 0.1rem 1rem 0.1rem 1rem;
          border-radius: 0.5rem;
          color: white;
          font-size: 0.8rem;
        }
        .done {
          height: 1.5rem;
          width: auto;
          background-color: $green;
          padding: 0.1rem 1rem 0.1rem 1rem;
          border-radius: 0.5rem;
          color: white;
          font-size: 0.8rem;
        }
        .onprogress {
            height: 1.5rem;
            width: auto;
            background-color: $primary3-color;
            padding: 0.1rem 1rem 0.1rem 1rem;
            border-radius: 0.5rem;
            color: white;
            font-size: 0.8rem;
          }
        .cancel {
            height: 1.5rem;
            width: auto;
            background-color: $red;
            padding: 0.1rem 1rem 0.1rem 1rem;
            border-radius: 0.5rem;
            color: white;
            font-size: 0.8rem;
          }
    }
  }
}
