@import '../variable/color';

.add-saved-packages-modal {
  font-family: "Roboto", sans-serif;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 30vw;
    margin: auto;
    // margin-top: 6vh;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .add-saved-packages-modal-header {
    border: none;

    .modal-title {
      width: 100%;
      border-bottom: 1px solid $light-grey2;
      padding-bottom: 1rem;
    }

    .packages-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  .add-saved-packages-modal-body {
    position: relative;
    height: 60vh;
    padding-inline: 0;
    padding: 1rem;
    padding-top: 0.5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      font-weight: 500;
    }

    .add-packages-body {
      position: relative;

      // background-color: red;
      .package-category {
        width: 100%;
        gap: 0.5rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 1rem;

        .package-choices {
          // flex-basis: auto;
          // width: fit-content;
          font-size: 0.75rem;
          font-weight: 500;
          background-color: $bg-grey;
          color: $dark-grey2;
          border-color: $bg-light-grey;
          border-radius: 0.5rem !important;

          &.active {
            color: $primary2-color;
            border-color: $primary2-color;
          }

          &:hover {
            border-color: $primary2-color;
          }
        }
      }

      // .search-package {
      //   position: absolute;
      //   right: 3rem;
      //   margin-top: 0.9rem;
      // }
      input {
        &[type='text'] {
          height: 2.5rem;
          margin-bottom: 2rem;
          padding-inline: 0.8rem;
          border-radius: 0.5rem;
          border: none;
          border: 1px solid rgba(209, 209, 209, 0.3);
          width: 100%;
          font-size: 0.75rem;

          &::placeholder {
            font-size: 0.75rem;
            opacity: 0.3;
          }

          &.error {
            border-color: red;
          }
        }

        &#lenght {
          width: 30%;
          margin-right: 0.8rem;
        }

        &#width {
          width: 30%;
          margin-right: 0.8rem;
        }

        &#height {
          width: 30%;
        }

        &#package-quantity {
          margin-bottom: 1rem;
        }

        // &#package-label {
        //   margin-bottom: -1rem;
        // }
        &::placeholder {
          font-size: 0.875rem;
        }

        &[type='checkbox'] {
          margin-top: 1rem;
          margin-bottom: 2rem;
          margin-right: 0.5rem;
        }

      }

      .range {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;

        .rc-slider-dot {
          opacity: 0;
        }

        h6 {
          text-align: end;
          width: 18%;
          margin: 0;
          font-size: 0.875rem;
        }
      }

      label:not(label#temperature-label, label.unit, label:first-child, label.label-error) {
        position: absolute;
        font-size: 0.75rem;
        margin-left: 0.9rem;
        margin-top: -0.6rem;
        background-color: white;
        padding: 0 5px;
        color: $dark-grey2;
      }

      label#temperature-label {
        font-size: 0.75rem;
        color: $dark-grey2;
        margin-bottom: 1rem;
      }

      label:first-child {
        font-size: 0.75rem;
        color: $dark-grey2;
        margin-left: 0.9rem;
        margin-bottom: 0.5rem;
      }

      label.unit {
        position: absolute;
        background-color: white;
        margin-left: 3.5rem;
        margin-top: 0.7rem;
        color: $dark-grey2;
        // &.kg {
        //   right: 3rem;
        // }
      }

      p {
        color: #bf1650;
        position: absolute;
        font-size: 0.75rem;
        margin-top: -2rem;
      }

      label.label-error {
        width: 6rem;
        color: #bf1650;
        position: absolute;
        font-size: 0.75rem;
        margin-top: 2.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .add-saved-packages-modal-footer {
    border: none;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1rem;

    .close {
      width: 48%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $bg-light-grey;
      color: $primary2-color;
    }

    .save {
      width: 48%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $primary2-color;
    }
  }
}

@media only screen and (max-width: 767px) {
  .add-saved-packages-modal {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 6vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}