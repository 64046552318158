@import '../../../styles/variable/color';

.export-btn-container {
  background-color: $bg-light-grey;
  color: $primary2-color;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.8125rem 1.5rem;
  border-radius: 0.625rem;
  justify-content: space-between;
  gap: 0.5rem;

  p {
    margin: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $light-grey1;
  }

  &:active {
    background-color: darken($color: $light-grey1, $amount: 3);
  }
}