$bg-surface-white: #FCFCFC;
$bg-surface-gray: #F2F3F5;


$text-primary: #0F0F0F;
$text-secondary: #5b5b5c;
$text-tertiary: #747475;
$text-disable: #c0c0c2;


$primary-20: rgba(223, 244, 250, 0.50);
$primary-50: #dff4fa;
$primary-100: #b0e4f2;
$primary-200: #7dd2e9;
$primary-300: #4ac0dd;
$primary-400: #27b4d4;
$primary-500: #12a8cb;
$primary-600: #0d99b8;
$primary-700: #04859e;
$primary-800: #007286;
$primary-900: #00505b;


$secondary-20: rgba(255, 225, 186, 0.5);
$secondary-50: #ffe1ba;
$secondary-100: #f3bc99;
$secondary-200: #d09672;
$secondary-300: #ac714a;
$secondary-400: #92562d;
$secondary-500: #783c0e;
$secondary-600: #6d3309;
$secondary-700: #5e2700;
$secondary-800: #511900;
$secondary-900: #430900;


$gray-20: rgba(244, 249, 250, 0.5);
$gray-50: #f4f9fa;
$gray-100: #ebf0f1;
$gray-200: #e0e5e6;
$gray-300: #cfd3d5;
$gray-400: #aaafb0;
$gray-500: #8a8e90;
$gray-600: #626768;
$gray-700: #4f5355;
$gray-800: #2f2f2f;
$gray-900: #0f0f0f;

$blue-400: #2F6BFF;
$blue-500: #2B61E8;

$red-100: #F33;
$red-200: #E52E2E;

$green-200: #00A151;

$orange-300: #FFC500;