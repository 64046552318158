.input-saved-address {
  font-family: "Roboto", sans-serif;

  .modal-content {
    width: 27vw;
    margin: auto;
    // margin-top: 20vh;
    padding-bottom: 1rem;
    padding-inline: 0;
  }

  .input-saved-address-header {
    padding: 2rem;
    padding-bottom: 0;
    border: none;
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .input-saved-address-body {
    position: relative;
    height: 35vh;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-inline: 1.8rem;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .address-data {
      margin-bottom: 0rem;
      cursor: pointer;
      padding-block: 0.7rem;

      &:hover {
        background-color: rgb(241, 241, 241);
      }

      .address-label {
        font-size: 0.875rem;
        font-weight: 700;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      .address-format {
        font-size: 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .input-saved-address {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 25vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}