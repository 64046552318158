@import '../variable/color';

.not-found-page {
  background-color: $bg-grey;
  height: 100vh;
  width: 100vw;
  position: relative; 
  z-index: 99999;
  padding: 5rem 9rem;
  box-sizing: border-box;
  overflow-y: hidden;
  .img-404 {
    width: 100%;
    height: 100vh;
    margin-top: -4rem;
  }
  .not-found-container {
    .title {
      color: $primary2-color;
      font-size: 5rem;
      font-weight: 900;
    }
    .content  {
      width: fit-content;
      margin: auto;
      margin-top: -3rem;
      padding-left: 11rem;
      div {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;
        .text-1 {
          color: $primary2-color;
          font-size: 5rem;
          font-weight: 900;
          margin: 0;
        }
        img {
          width: 28rem;
          margin-left: -9rem;
          margin-bottom: -3.2rem;
        }
      }
      .text-2 {
        color: $primary2-color;
        font-size: 3rem;
        font-weight: 900;
      }
    }
  }
}