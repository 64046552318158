@import '../../../styles/variable/color';
@import '../../../styles/variable/color_v2';

.custom-btn {
  border: none !important;

  &.btn-primary {
    background-color: $primary-800 !important;

    &:hover {
      background-color: $primary-900 !important;
    }

    &.disabled {
      background-color: $primary-900 !important;
    }

    &.inactive {
      background-color: $gray-400 !important;
    }
  }

  &.btn-secondary {
    color: $primary2-color;
    background-color: $bg-light-grey;
  }
}