@import '../variable/color';

.update-password {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .update-password-bg {
    width: 100%;
    height: 50vh;
    padding: 7rem 0 0 20rem;
    background-size: cover;
    background-repeat: no-repeat;
    .text-container {
      margin-bottom: 2rem;
      color: white;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .container-form {
    position: relative;
    top: -7rem;
    width: 40rem;
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    .form-header {
      margin-bottom: 2rem;
      p {
        font-size: 0.8rem;
        color: gray;
        margin-bottom: 1.5rem;
      }
    }
    .form-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .form-request-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .input-wrapper {
          position: relative;
          .input-text.error {
            border-color: red;
          }
          .input-text {
            &:focus {
              outline: none;
              border-color: $primary2-color;
            }
          }
          .show-password{
              position: absolute;
              right: 0.5rem;
              top: 1.9rem;
              cursor: pointer;
          }
          p {
            color: $red;
            position: absolute;
            font-size: 0.875rem;
            margin-top: -0.8rem;
          }
          
          p::before {
            display: inline;
          }
        }
        .input-text {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 17rem;
          margin: 1rem auto;
        }

        .label-input {
          position: absolute;
          color: grey;
          top: 0.3rem;
          z-index: 1;
          left: 2em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }
        .input-wrapper-address {
          position: relative;
        }
        .input-text-address {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 36rem;
          margin: 1rem auto;
        }

        .container-validation {
          width: 100%;
          margin-top: 0rem;
          padding-left: 1rem;
          margin-bottom: 1rem;
          .validator {
            display: flex;
            align-items: center;
            gap: 0.3rem;
            .not-valid {
              color: $red;
              font-size: 0.7rem;
              &+.validator-text {
                color: $red;
              }
            }
            .valid {
              color: $green;
              font-size: 0.875rem;
            }
            .validator-text {
              margin: 0;
              font-size: 0.875rem;
            }
          }
        }


        .form-footer {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          align-items: center;
          padding: 3rem 0.5rem 0 0;
          .btn-submit-request {
            width: 8rem;
            background-color: $primary2-color;
            border-style: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .update-password {
    .update-password-bg {
      padding-inline: 16px 0;
    }
    .container-form {
      width: 95%;
      top: -10rem;
      .form-body {
        .form-request-container {
          .input-wrapper {
            width: 100%;
            margin-bottom: 1rem;
            .input-text {
              width: 100%;
            }
          }
        }
      }
    }
  }
}