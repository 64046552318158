@import '../../../../styles/variable/color_v2';

.order-planning {
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 7rem;
  padding-bottom: 1.5rem;
  padding-inline: 1.5rem;
  background-color: $bg-surface-gray;
  font-family: 'Inter', sans-serif;
  overflow-y: scroll;

  .title {
    h1 {
      color: $text-primary;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      letter-spacing: 0.0125rem;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 0.875rem;
      color: $text-tertiary
    }
  }

  .order-planning-form {
    .title {
      h1 {
        color: $text-primary;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.625rem;
        letter-spacing: 0.0125rem;
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
      background-color: white;
      margin-bottom: 0.3rem;
    }

    .link-order-template {
      color: $blue-400;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.625rem;
      /* 216.667% */
      letter-spacing: 0.0075rem;
      margin-top: 0.75rem;
    }
  }
}