@import '../../../../styles/variable/color_v2';

.data-sku {
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 7rem;
  padding-bottom: 1.5rem;
  padding-inline: 1.5rem;
  background-color: $bg-surface-gray;
  font-family: 'Inter', sans-serif;
  overflow-y: scroll;

  .title {
    h1 {
      color: $text-primary;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      letter-spacing: 0.0125rem;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 0.875rem;
      color: $text-tertiary
    }
  }
}