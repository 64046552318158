@import '../variable/color';

.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;

  .main-content {
    width: 50vw;
    height: 95vh;
    background-color: white;
    border-radius: 1.5rem;
    padding: 2rem;
    overflow-y: scroll;
    scrollbar-width: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .map-view {
      width: 100%;
      height: 35%;
      border-radius: 1.5rem;
      border: 1px solid lightgray;
      margin-bottom: 1.5rem;
      position: relative;

      .maps {
        width: 100%;
        height: 35vh;
        border-radius: 1.5rem;
      }

      .input-address {
        position: absolute;
        top: 8%;
        margin-left: 1rem;
        font-size: 0.875rem;

        input {
          width: 25vw;
          padding: 0.3rem 0.8rem;
          box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
          border: none;
          border-radius: 0.3rem;
        }
      }
    }

    .form-add-address {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .input-wrapper-address {
        position: relative;
        width: 100%;

        .label-address {
          position: absolute;
          color: $grey1;
          top: 0.3rem;
          z-index: 1;
          left: 1em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }

        .text-address {
          padding: 0.6rem;
          font-size: 0.875rem;
          box-sizing: border-box;
          border-radius: 0.8rem;
          position: relative;
          width: 100%;
          margin: 1rem auto;
        }
      }

      .input-wrapper-location {
        position: relative;
        width: 48%;

        // &.label {
        //   width: 100%;
        // }
        &.notes {
          width: 100%;
        }

        .label-input-location {
          position: absolute;
          color: #a1b3ce;
          top: 0.3rem;
          z-index: 1;
          left: 1em;
          background-color: white;
          padding: 0 5px;
          font-size: 0.8rem;
        }

        .phone-62 {
          position: absolute;
          margin-top: 1.8rem;
          z-index: 99;
          margin-left: 0.8rem;
          border-right: 1px solid black;
          padding-right: 0.7rem;
          font-size: 0.875rem;
        }

        .input-text-location {
          padding: 0.6rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 100%;
          font-size: 0.875rem;
          margin: 1rem auto;

          &#phoneNumber {
            padding-left: 3rem;
          }
        }

        .select-tag__control {
          padding: 0.15rem;
          box-sizing: border-box;
          border: 1px solid lightgray;
          border-radius: 0.8rem;
          position: relative;
          width: 100%;
          font-size: 0.875rem;
          margin: 1rem auto;
        }
      }
    }

    .btn-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 1rem;

      .close-btn {
        height: 2.5rem;
        width: 7rem;
        border-style: none;
        border-radius: 1rem;
        margin-right: 1rem;
        color: #1f83bb;
        font-weight: 600;
      }

      .close-btn:hover {
        opacity: .5;
      }

      .btn-save {
        height: 2.5rem;
        width: 7rem;
        border-style: none;
        border-radius: 1rem;
        color: white;
        font-weight: 600;
        background-color: #1f83bb;
      }

      .btn-save:hover {
        opacity: .5;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .overlay {
    .main-content {
      width: 95vw;

      .map-view {
        .input-address {
          position: fixed;
          margin-top: 1rem;

          input {
            width: 70vw;
            height: 2rem;
          }
        }
      }

      .form-add-address {
        .input-wrapper-address {
          .text-address {
            min-height: 2rem;
          }
        }

        .input-wrapper-location {
          width: 100%;
        }
      }
    }
  }
}