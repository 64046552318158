@import '../../styles/variable/color';

.multiorder-modal {
  font-family: "Roboto", sans-serif;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 23vw;
    margin: auto;
    // margin-top: 10vh;
    padding-inline: 0;
    border-radius: 1rem;
  }

  .multiorder-modal-header {
    padding: 1.5rem;
    padding-bottom: 0;
    border: none;

    h5 {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
      margin-inline: auto;
    }
  }

  .multiorder-modal-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    padding: 1rem 1.8rem;
    text-align: center;

    .wrapper {
      width: 100%;
      position: relative;

      .input-text {
        width: 100%;
        padding: 0.6rem;
        box-sizing: border-box;
        border: 1px solid lightgray;
        border-radius: 0.8rem;
        position: relative;
        margin: 0.5rem auto;
        font-weight: 700;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        cursor: pointer;
      }

      .icon {
        position: absolute;
        right: 0.6rem;
        top: 1rem;

        img {
          background-color: white;
          margin-left: 0.5rem;

          &:last-child {
            cursor: pointer;
          }
        }
      }
    }

    .add-loc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 700;
      font-size: 0.875rem;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);

      &:active {
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1) inset;
      }
    }
  }

  .multiorder-modal-footer {
    border: none;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1rem;
    padding-top: 0;

    .rating-skip {
      width: 50%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      color: $primary2-color;
      background-color: $bg-light-grey;
    }

    .rating-submit {
      width: 50%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $primary2-color;

      &.loading {
        cursor: wait;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .multiorder-modal {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 20vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}