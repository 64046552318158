@import '../../styles/variable/color_v2';

.default-input {
  input {
    &[type='text'] {
      height: 2.5rem;
      margin-bottom: 1.5rem;
      padding-inline: 0.5rem;
      border-radius: 0.5rem;
      border: none;
      border: 1px solid $gray-300;
      width: 100%;
      font-size: 0.875rem;

      &.error {
        border-color: red;
      }
    }

    &::placeholder {
      font-size: 0.875rem;
    }

    &[type='checkbox'] {
      cursor: pointer;
      border-radius: 50%;
      margin-top: 1rem;
      margin-bottom: 2rem;
      margin-right: 0.5rem;
    }
  }

  textarea {
    height: 7rem;
    margin-bottom: 1.5rem;
    padding-inline: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    border: 1px solid rgba(209, 209, 209, 0.3);
    width: 100%;
    font-size: 0.875rem;

    &.error {
      border-color: red;
    }
  }

  label {
    font-size: 1rem;
    font-weight: 700;
    background-color: white;
    margin-bottom: 0.3rem;
  }

  p {
    color: #bf1650;
    font-size: 0.75rem;
    margin-top: -1.2rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }

  // .input-select-content__control {
  //   background-color: red;
  // }

  // * React Select
  .input-select-content__menu {
    z-index: 2;
    border: none !important;
  }

  .select-data {
    &>div {
      border-radius: 0.5rem;
      padding: 0;
      // background-color: yellow;
      font-size: 0.875rem;
      margin-top: 0;

      .css-3w2yfm-ValueContainer,
      .css-1fdsijx-ValueContainer,
      .input-select-content__value-container {
        // height: 2.5rem;
        height: fit-content;
        padding: 0;

        .css-1p3m7a8-multiValue,
        .css-1dimb5e-singleValue,
        .css-1jqq78o-placeholder {
          // background-color: red;
          margin-left: 0.5rem;
          border-radius: 0.25rem;
        }

        .css-1p3m7a8-multiValue {
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }

        .css-qbdosj-Input,
        .css-166bipr-Input {
          margin-left: 0.5rem;
          margin-top: -0.1rem;
          border-radius: 0.25rem;
          padding: 0;

          input {
            font-size: 0.875rem;
          }
        }
      }

      .css-1fdsijx-ValueContainer {
        height: 2.5rem;
      }
    }

    .input-select-content__control--is-disabled {
      background-color: hsla(0, 0%, 95%, 0.645);
      background-color: #bf1650;
    }
  }

  .select-data-error {
    margin-bottom: 1.5rem;

    &>div:first-of-type {
      border: 1px solid red;
      border-radius: 0.5rem;
      padding: 0;
      // background-color: yellow;
      font-size: 0.875rem;
      margin-top: 0;

      .css-3w2yfm-ValueContainer,
      .css-1fdsijx-ValueContainer {
        height: 2.5rem;
        padding: 0;

        .css-1p3m7a8-multiValue,
        .css-1dimb5e-singleValue,
        .css-1jqq78o-placeholder {
          // background-color: red;
          margin-left: 0.5rem;
          margin-top: -0.2rem;
          border-radius: 0.25rem;
        }

        .css-qbdosj-Input,
        .css-166bipr-Input {
          margin-left: 0.5rem;
          margin-top: -0.1rem;
          border-radius: 0.25rem;

          input {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  // * React Date Range/Calendar 
  &.calendarWrap {
    width: 100%;
    height: 100%;
    position: relative;


    .inputBox {
      height: 2.5rem;
      margin-bottom: 0rem;
      padding-inline: 0.5rem;
      border-radius: 0.5rem;
      border: none;
      border: 1px solid $gray-300;
      width: 100%;
      font-size: 0.875rem;
      text-align: start;
      color: #333333;

      &.error {
        border-color: red;
      }
    }

    img {
      position: absolute;
      right: 0.5rem;
      top: 2.3rem;
    }

    p {
      margin-top: 0.3rem;
      margin-bottom: 1rem;
      margin-left: 0.5rem;
    }

    .calendarElement {
      position: absolute;
      z-index: 5;
      box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.08);
    }
  }


}