@import '../../../styles/variable/color_v2';

.export-btn-container {
  background-color: $primary-20;
  color: $primary-800;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.625rem;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 0.875rem;

  p {
    margin: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $primary-50;
  }

  &:active {
    background-color: $primary-100;
  }
}