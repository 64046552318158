@import '../../variable/color';

.order-confirm-manage {
  .order-side {
    padding-inline: 1.5rem;
    margin-top: 9rem;
    padding-top: 4rem;
    width: 37vw;
    height: 100vh;
    border-radius: 0 2rem 0 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .order-info {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.4rem;
        h3 {
          font-size: 0.875rem;
        }
      }
      .title-info {
        margin-bottom: 1rem;
        .title {
          font-size: 1rem;
          font-weight: 700;
          margin: 0;
        }
        .estimation {
          display: flex;
          align-items: center;
          background-color: $dark-grey !important;
          color: $dark-grey2 !important;
          gap: 0.5rem;
        }
      }
    }
    .service-info {
      .title {
        font-size: 1rem;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;
        h3 {
          font-size: 0.875rem;
        }
      }
    }
    .driver {
      margin-bottom: 20rem;
      .title {
        font-size: 1rem;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      div {
        display: flex;
        align-items: center;
        gap: 1rem;
        .driver-on {
          width: 0.7rem;
          height: 0.7rem;
          background-color: $green;
          border-radius: 50%;
        }
        .driver-img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
        }
        .driver-data {
          display: block;
          .driver-name {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }
          .driver-bike-number {
            margin: 0;
            font-size: 0.875rem;
          }
        }
      }
      &>div {
        margin-bottom: 1.5rem;
      }
    }
  }
  .detail-side {
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 35vw;
    left: 0;
    position: absolute;
    padding: 1.5rem 2rem 1rem 3rem;
    border-radius: 0 2rem 0 0;
    transition: all 0.5s ease;
    z-index: -2;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.show-detail{
      left: 35vw;
      transition: all 0.5s ease;
    }
    .detail-header {
      display: grid;
      position: fixed;
      width: inherit;
      left: inherit;
      padding: 1.5rem 2rem 1rem 3rem;
      border-radius: 0 2rem 0 0;
      align-items: center;
      grid-template-areas: 
        'trip trip none none price'
        'optimize optimize optimize optimize optimize'
        'estimation estimation estimation weights weights'
      ;
      row-gap: 1rem;
      background-color: white;
      padding-bottom: 1rem;
      top: 0;
      z-index: 99;
      .trip {
        text-align: center;
        grid-area: trip;
        font-size: 0.75rem;
        background-color: #e4f6ff;
        padding: 0.2rem 0.2rem;
        border-radius: 0.3rem;
        span {
          font-weight: 700;
        }
      }
      .price {
        font-size: 1rem;
        font-weight: 600;
        grid-area: price;
      }
      .optimize {
        grid-area: optimize;
        background-color: #e4f6ff;
        padding: 0.8rem 0.6rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        h1 {
          font-size: 1rem;
          font-weight: 700;
          margin: 0;
        }
      }
      .estimation {
        grid-area: estimation;
        font-size: 0.75rem;
        font-weight: 600;
        padding: 0.2rem 0.4rem;
        margin-right: 0.5rem;
        border-radius: 0.3rem;
        color: #949494;
        background-color: #e4f6ff;
      }
      .weights {
        grid-area: weights;
        font-size: 0.75rem;
        font-weight: 600;
        padding: 0.2rem 0.4rem;
        margin-right: 2rem;
        border-radius: 0.3rem;
        color: #949494;
        background-color: #e4f6ff;
      }
    }
    .detail-data {
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      margin-top: 9rem;
      .detail-point {
        width: 13%;
        .detail-index {
          position: relative;
          margin-top: 2.5rem;
          margin-bottom: 5.7rem;
          h1 {
            margin: 0;
            font-size: 1.5rem;
            text-align: center;
            border-radius: 50%;
            padding: 0.3rem;
            color: #11567e;
            background-color: #63b6e6;
            z-index: 99;
          }
          &::before {
            content: " ";
            height: 210%;
            width: 1px;
            position: absolute;
            left: 50%;
            top: -225%;
            z-index: -1;
            border: 1px dashed #63b6e6;
          }
        }
      }
      .detail-datas {
        width: 88%;
        .detail-data-trip {
          width: 100%;
          .trip-point {
            border: 1px solid #e0e0e0;
            border-radius: 1rem;
            padding: 1rem;
            .trip-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .trip-name {
                font-size: 1rem;
                margin: 0;
              }
              h3 {
                font-size: 0.875rem;
                margin: 0;
                color: #63b6e6;
              }
            }
            .loc-name {
              font-size: 1rem;
              font-weight: 700;
            }
            .loc-address {
              font-size: 0.875rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:first-child {
              .trip-title {
                h3{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .detail-footer {
      display: flex;
      position: fixed;
      width: inherit;
      left: inherit;
      bottom: 0;
      background-color: white;
      justify-content: space-between;
      padding: 1rem 2rem 1rem 3rem;
      gap: 1rem;
      .back {
        width: 50%;
        height: 2.5rem;
        font-size: 0.875rem;
        background-color: #e0e0e0;
        &:hover {
          background-color: darken(#e0e0e0, 10%);
        }
      }
      .save {
        width: 50%;
        font-size: 0.875rem;
      }
    }
  }
}

