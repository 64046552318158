@import '../../../styles/variable/color';
@import '../../../styles/variable/color_v2';

.custom-table {

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
  }

  /* -----Search-filter */
  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 1.4;
    color: #475f7b;
    background-color: #fff;
    border: 1px solid #dfe3e7;
    border-radius: 0.267rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control:focus {
    color: #475f7b;
    background-color: #fff;
    border-color: $primary-800;
    outline: 0;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
  }

  .card {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
  }

  .pagination-data {
    padding: 0;
    margin: 0;
  }

  .pagination-data li {
    list-style: none;
  }

  .table-filter-info {
    padding: 15px;
  }

  .thead-primary tr th {
    background-color: $primary-800;
    border-color: $primary-800;
    color: #fff;
  }

  .rc-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
  }

  .rc-pagination-item,
  .rc-pagination-prev,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next,
  .rc-pagination-options {
    margin-right: 8px;
    list-style: none;
  }

  .rc-pagination-total-text {
    margin-right: 12px;
    cursor: initial;
    list-style: none;
    order: 1;
    width: 100%;
    text-align: end;
  }

  .rc-pagination-jump-next,
  .rc-pagination-jump-prev,
  .rc-pagination-next,
  .rc-pagination-prev {
    display: inline-block;
    min-width: 28px;
    height: 28px;
    color: rgba(0, 0, 0, .85);
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all .3s;
  }

  .rc-pagination-jump-next button,
  .rc-pagination-jump-prev button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
  }

  .rc-pagination-jump-next button:after,
  .rc-pagination-jump-prev button:after {
    display: block;
    content: "•••";
  }

  .rc-pagination-item,
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-total-text {
    min-width: initial;
    height: auto;
    line-height: initial;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }

  .rc-pagination-item a,
  .rc-pagination-item button,
  .rc-pagination-prev a,
  .rc-pagination-prev svg,
  .rc-pagination-next a,
  .rc-pagination-next svg,
  .rc-pagination-total-text a,
  .rc-pagination-total-text button {
    padding: 6px 8px;
    height: auto;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    // border: 1px solid transparent;
    border: 1px solid $gray-300;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #656f84 !important;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .rc-pagination-item.rc-pagination-item-active a,
  .rc-pagination-item.rc-pagination-item-active a:hover,
  .rc-pagination-prev.rc-pagination-item-active a,
  .rc-pagination-prev.rc-pagination-item-active a:hover,
  .rc-pagination-next.rc-pagination-item-active a,
  .rc-pagination-next.rc-pagination-item-active a:hover,
  .rc-pagination-total-text.rc-pagination-item-active a,
  .rc-pagination-total-text.rc-pagination-item-active a:hover {
    background-color: $primary-800;
    border-color: $primary-800;
    color: #ffffff !important;
  }

  .rc-pagination-item a:hover,
  .rc-pagination-item button:hover,
  .rc-pagination-prev a:hover,
  .rc-pagination-prev svg:hover,
  .rc-pagination-next a:hover,
  .rc-pagination-next svg:hover,
  .rc-pagination-total-text a:hover,
  .rc-pagination-total-text button:hover {
    background-color: $gray-200;
    border-color: $gray-200;
  }
}