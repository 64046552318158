@import '../../../styles/variable/color_v2';

.primary-button {
  border: 1px solid $primary-800 !important;
  background-color: $primary-800 !important;
  padding: 0;
  padding: 0.5rem 1.5rem;
  display: flex !important;
  align-items: center;
  gap: 0.3rem;

  &:hover {
    background-color: $primary-900 !important;
  }

  p {
    margin: auto;
  }

  // &.disabled {
  //   background-color: $primary-800;
  //   opacity: 0.24;
  // }
}