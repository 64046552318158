@import '../../../styles/variable/color_v2';

.input-search {
  width: 100%;
  border-radius: 0.8rem;
  font-family: "Inter", sans-serif;
  padding-left: 0.1rem;
  position: relative;
  // margin-top: 1rem;

  .search-transactions {
    width: 100%;
    height: 100%;
    border: 0.1rem solid $gray-300;
    padding: 0.5rem;
    padding-right: 2.2rem;
    border-radius: 0.5rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    /* 1.5rem */
  }

  img {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
    color: #333;
  }
}