.skeleton {
  display: inline-block;
  position: relative;
  background: rgb(232, 232, 232);
  overflow: hidden;
  // animation: skeleton-loading 0.5s linear infinite alternate;
}

.skeleton::after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(232, 232, 232, 1) 0%, rgba(218, 218, 218, 1) 50%, rgba(232, 232, 232, 1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 1.5s linear infinite;
}

// @keyframes skeleton-loading {
//   0% {
//     background-color: hsl(200, 20%, 80%);
//   }

//   100% {
//     background-color: hsl(200, 20%, 95%);
//   }
// }

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}

.loader::after {
  content: '';
  width: 192px;
  height: 4.8px;
  background: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }

  100% {
    left: 100%;
    transform: translateX(0%);
  }
}


// .skeleton-text {
//   // width: 100%;
//   // height: 0.7rem;
//   // margin-bottom: 0.5rem;
//   border-radius: 0.25rem;
// }

// .skeleton-text__body {
//   width: 75%;
// }

// .skeleton-footer {
//   width: 30%;
// }