.alert-popup {
  width: 20vw;
  font-family: 'Inter', sans-serif;

  .alert-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;

    img {
      margin: 0
    }

    .alert-popup-title {
      margin: 0;
      width: 80%;
    }

    .alert-popup-time {
      font-size: 0.75rem;
      margin: 0;
      cursor: pointer;
    }
  }

  .alert-popup-body {
    margin: 0;
    margin-top: 0.8rem;
  }
}