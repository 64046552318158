@import '../../../variable/color';

.transaction-detail-point {
  font-family: "Roboto", sans-serif;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 30vw;
    margin: auto;
    // margin-top: 6vh;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .transaction-detail-point-header {
    border: none;

    .modal-title {
      width: 100%;
      border-bottom: 1px solid $light-grey2;
      padding-bottom: 1rem;
    }

    .header-content {
      display: flex;
      width: 1005;
      justify-content: space-between;
      align-items: center;

      div {
        h3 {
          font-size: 1.125rem;
          font-weight: 700;
        }

        h5 {
          font-size: 1rem;
        }
      }

      .done {
        background-color: $green !important;
      }

      .onprogress {
        background-color: $bg-light-grey !important;
        color: $dark-grey2 ;
      }

      .pending {
        background-color: $red !important;
      }
    }
  }

  .transaction-detail-point-body {
    position: relative;
    height: 60vh;
    padding-inline: 0;
    padding: 1rem;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .estimation-actual {
      margin-top: -1rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .estimation {
        width: 100%;
        text-align: center;
        background-color: $bg-light-grey;
        color: $primary2-color;
        font-size: 0.75rem;
        font-weight: 700;
        padding-block: 0.3rem;
        border-radius: 0.5rem;
      }

      .actual {
        width: 100%;
        text-align: center;
        background-color: $bg-light-grey;
        color: $primary2-color;
        font-size: 0.75rem;
        font-weight: 700;
        padding-block: 0.3rem;
        border-radius: 0.5rem;
      }
    }

    .address {
      margin-top: 1rem;
      border: 1px solid $light-grey2;
      border-radius: 0.8rem;
      padding: 0.5rem;

      .address-info {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        .address-data {
          .address-label {
            font-size: 0.875rem;
            font-weight: 700;
          }

          .address-loc {
            font-size: 0.75rem;
            color: $dark-grey2;
          }

          .address-note {
            font-size: 0.875rem;
            font-weight: 700;
          }

          .address-note-content {
            font-size: 0.75rem;
            margin: 0;
            color: $dark-grey2;
          }
        }
      }

      .price {
        margin-top: 0.8rem;
        width: 100%;
        text-align: center;
        background-color: $bg-light-grey;
        color: $primary2-color;
        font-size: 0.75rem;
        font-weight: 700;
        padding-block: 0.3rem;
        border-radius: 0.5rem;
      }
    }

    .contact {
      margin-top: 1rem;
      padding-block: 1rem;
      border-block: 1px solid $light-grey2;

      .title {
        font-size: 1rem;
        font-weight: 700;
      }

      .contact-info-content {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 3rem;

        .name {
          width: 50%;

          h4 {
            color: $dark-grey2;
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 0.2rem;
          }

          .desc {
            margin-bottom: 0;
            font-size: 0.875rem;
          }
        }

        .phone-number {
          width: 50%;

          h4 {
            color: $dark-grey2;
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 0.2rem;
          }

          .desc {
            margin-bottom: 0;
            font-size: 0.875rem;
          }
        }
      }
    }

    .list-packages {
      margin-top: 1rem;
      border-bottom: 1px solid $light-grey2;

      .packages-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 1rem;
          font-weight: 700;
        }

        .total {
          font-size: 0.875rem;
          font-weight: 500;
          color: $dark-grey2;
        }
      }

      .packages {
        .packages-data {
          margin-top: 0.5rem;

          h6 {
            font-size: 0.875rem;
            font-weight: 700;
          }

          p {
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }
    }

    .pickup-proven {
      margin-top: 1rem;

      .title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .proven-data {
        width: 100%;
        margin-bottom: 1.2rem;

        h3 {
          color: $dark-grey2;
          font-size: 0.75rem;
          font-weight: 600;
          margin-bottom: 0.4rem;
        }

        .desc {
          margin-bottom: 0;
          font-size: 0.875rem;
        }

        .photo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          width: 100%;

          img {
            width: 50%;
            height: 8rem;
            border-radius: 0.5rem;
          }
        }
      }
    }

    .pending-report {
      margin-top: 1rem;
      border-bottom: 1px solid $light-grey2;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
      }

      .report-notes {
        margin-top: 2rem;

        h6 {
          font-size: 0.875rem;
          font-weight: 700;
        }

        p {
          font-size: 0.75rem;
          font-weight: 500;
          color: $dark-grey2;
        }
      }

      .report-store-loc {
        margin-top: 2rem;

        h6 {
          font-size: 0.875rem;
          font-weight: 700;
        }

        .warehouse-label {
          color: $dark-grey2;
        }

        p {
          font-size: 0.75rem;
          font-weight: 500;
          color: $dark-grey2;
        }
      }
    }
  }

  .transaction-detail-point-footer {
    border: none;
    justify-content: flex-end;
    flex-wrap: nowrap;
    padding: 1rem;
    padding-top: 0;

    .close {
      width: 35%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $primary2-color;

      &.loading {
        cursor: wait;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .transaction-detail-point {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 6vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}