@import '../variable/color';

.container-profile {
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container-title-profile {
    width: 100%;
    height: 20rem;
    padding: 7rem 120px 0;
    background-size: cover;
    background-repeat: no-repeat;
    .content-title-profile {
      h3 {
        color: white;
      }
      .link {
        display: flex;
        list-style-type: none;
        text-decoration: none;
        padding: 0;
        li {
          text-decoration: none;
          .link-to {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
  .content-profile {
    position: relative;
    top: -7rem;
    width: 100%;
    height: 50rem;
    padding: 0 120px 0;
    display: flex;
    justify-content: space-between;
    gap: 1.3rem;
    .sidebar-profile {
      height: fit-content;
      width: fit-content;
      min-width: 20rem;
      max-width: 30%;
      background-color: white;
      border-radius: 1.5rem;
      padding: 1.5rem 1.5rem 2rem 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      .header-sidebar {
        display: flex;
        height: 7rem;
        width: 100%;
        align-items: center;
        border-bottom: 0.1rem solid lightgray;
        gap: 1rem;
        justify-content: center;
        .img-container {
          height: 5rem;
          width: 5rem;
          display: flex;
          align-items: center;
          // background-color: red;
          border-radius: 50%;
          .placeholder {
            height: 100%;
            border-radius: 50%
          }
          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
        .detail-profile {
          width: 68%;
          .name {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .customer-id {
            font-size: 0.8rem;
            color: $dark-grey2;
            margin: 0;
          }
          .contact {
            font-size: 0.8rem;
            color: $dark-grey2;
            margin: 0;
          }
        }
      }
    }
    .request-coorporate {
      height: 2.5rem;
      width: 100%;
      background-color: $primary3-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.5rem;
      border-style: none;
      padding: 0 1rem 0 1rem;
      margin: 1rem 0 0.3rem 0;
      text-decoration: none;
      .icon-coorporate {
        color: white;
      }
      .text-coorporate {
        margin: 0;
        font-size: 0.9rem;
        color: white;
      }
      .corporate-badge {
        background-color: $primary2-color !important;
      }
    }
    .list-menu {
      display: flex;
      height: 3rem;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background: none;
      border-style: none;
      padding: 0;
      text-decoration: none;
      .icon-container {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        .icon-menu {
          height: 1.5rem;
          width: auto;
        }
      }
      .title-button {
        height: 2rem;
        width: 12rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: gray;
        font-weight: 600;
      }
      .arrow-icon {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-arrow {
          height: 1.5rem;
          width: auto;
          color: #b7c7df;
        }
      }
    }
    .list-menu:hover {
      background-color: aliceblue;
    }
    .list-menu-logout {
      display: flex;
      height: 3rem;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background: none;
      border-top: 1px solid lightgray;
      padding: 0;
      cursor: pointer;
      .icon-container {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        .icon-menu {
          height: 1.5rem;
          width: auto;
        }
      }
      .title-button {
        height: 2rem;
        width: 12rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: gray;
        font-weight: 600;
      }
      .arrow-icon {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icon-arrow {
          height: 1.5rem;
          width: auto;
          color: #b7c7df;
        }
      }
    }
    .list-menu-logout:hover {
      background-color: aliceblue;
    }
    .main-profile {
      height: auto;  
      width: 70%;   
    }
  }
}

@media only screen and (max-width: 767px) {
  .container-profile {
    .container-title-profile {
      padding: 7rem 4rem 0;
      .content-title-profile {
        h3 {
          font-size: 1.25rem;
        }
      }
      .link {
        font-size: 0.875rem;
      }
    }
    .content-profile {
      flex-direction: column;
      padding: 0 16px 0;
      margin-top: -1rem;
      margin-bottom: 2rem;
      height: fit-content;
      .sidebar-profile {
        width: 80%;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
      }
      .main-profile {
        width: 100%;  
      }
    }
  }
}
