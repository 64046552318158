$primary1-color: #C6E8F4;
$primary2-color: #1F83BB;
$primary3-color: #6EB9E3;
// text
$dark-grey: #E1EAF6;
$dark-grey1: #B7C7DF;
$dark-grey2: #839ABC;
$black: #061738;
$grey1: #95BDE1;
$grey2: #E6E6E6;
$light-grey1: #E3ECFA;
// border
$light-grey2: #EEEEEE;
$bg-light-grey: #F1F5FB;
$bg-grey: #F8FBFF;
$bg-grey2: #F5F5F5;
$green: #50B044;
$red: #EB5757;
$orange: #F4CD32;