@import '../../../styles/variable/color_v2';

.custom-table {
  .title-table {
    h1 {
      color: $text-primary;
      font-family: Inter;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.625rem;
      letter-spacing: 0.0125rem;
    }
  }

  .modify-table {
    display: flex;
    gap: 1rem;
    justify-content: end;
    margin-bottom: 1rem;
  }

  .filter-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .search-filter {
      width: 21.4375rem;
    }

    .btn-filter {
      display: flex;
      gap: 1rem;
      justify-content: end;
      align-items: center;
    }
  }

  #table {
    thead {
      background-color: $bg-surface-gray;
      border-bottom-color: $bg-surface-gray;
    }

    .bs-checkbox input {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
    }
  }
}