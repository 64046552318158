@import '../variable/color';

.edit-password{
  height: auto;
    background-color: white;
    border-radius: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
.header-edit-password {
    height: 3.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 0.1rem solid lightgray;
    margin-bottom: 1.2rem;
  }
  .body-edit-password {
    height: auto;
    .input-fill {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .input-wrapper {
        position: relative;
        margin-bottom: 1rem;
        width: 49%;
        &.old-pass {
          width: 100%;
          .input-text {
            width: 49%;
          }
        }
        .input-text.error {
          border-color: red;
        }
        .input-text {
          width: 100%;
          &:focus {
            outline: none;
            border-color: $primary2-color;
          }
        }
        .show-password{
            position: absolute;
            // right: 0.5rem;
            margin-left: -1.5rem;
            top: 1.9rem;
            cursor: pointer;
        }
        p {
          color: red;
          position: absolute;
          font-size: 0.875rem;
          margin-top: -0.8rem;
        }
        
        p::before {
          display: inline;
        }
      }
  
      .input-text {
        padding: 0.6rem;
        box-sizing: border-box;
        border: 1px solid lightgray;
        border-radius: 0.8rem;
        position: relative;
        width: 22rem;
        margin: 1rem auto;
      }
  
      .label-input {
        position: absolute;
        color: grey;
        top: 0.3rem;
        z-index: 1;
        left: 1em;
        font-size: 0.8rem;
        background-color: white;
        padding: 0 5px;
        font-size: 0.8rem;
      }

      .container-validation {
        width: 100%;
        margin-top: -1.5rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
        .validator {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          .not-valid {
            color: $red;
            font-size: 0.7rem;
            &+.validator-text {
              color: $red;
            }
          }
          .valid {
            color: $green;
            font-size: 0.875rem;
          }
          .validator-text {
            margin: 0;
            font-size: 0.875rem;
          }
        }
      }
    }
    .footer-edit-password {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1rem;
      .btn-submit-password {
        width: 6rem;
        height: 2rem;
        background-color: #1f83bb;
        border-style: none;
        color: white;
        font-weight: 600;
        border-radius: 0.5rem;
      }
    }
  }
}
  
@media only screen and (max-width: 767px) {
  .edit-password {
    .body-edit-password {
      .input-fill {
        .input-wrapper {
          &:first-child {
            width: 100%;
            .input-text {
              width: 100%;
            }
          }
          width: 100%;
        }
      }
    }
  }
}