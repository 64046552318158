@import '../variable/color';

.xendit-payment {
  font-family: "Roboto", sans-serif;

  &.loading {
    cursor: wait;
  }

  .modal-content {
    width: 25vw;
    margin: auto;
    // margin-top: 30vh;
    padding-inline: 0;
    border-radius: 1rem;
  }

  .rating-driver-body {
    position: relative;
    height: fit-content;
    padding-inline: 0;
    padding: 1rem 1rem;
    text-align: center;

    img {
      margin-block: 1rem 1.5rem;
    }

    h5 {
      font-weight: 600;
    }
  }

  .rating-driver-footer {
    border: none;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1rem;
    padding-top: 0;

    .rating-skip {
      width: 50%;
      font-size: 0.75rem;
      font-weight: 700;
      padding-block: 0.7rem;
      border-radius: 0.5rem;
      border: 1px solid $dark-grey1;
      color: $black;
      background-color: white;
    }

    .xendit-btn {
      border-radius: 0.5rem;
      width: 50%;

      .rating-submit {
        width: 100%;
        font-size: 0.75rem;
        font-weight: 700;
        padding-block: 0.7rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $primary2-color;

        &.loading {
          cursor: wait;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .xendit-payment {
    .modal-content {
      width: 95vw;
      margin: auto;
      margin-top: 25vh;
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }
}