@import '../variable/color';

.main-container {
    width: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .flag-status {
        width: 100%;
        border-radius: 1rem 1rem 0 0;
        padding: 0.5rem 1rem 1.4rem;
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: -1rem;

        &.success {
            background-color: $primary2-color;
        }

        &.waiting {
            background-color: $red;
        }
    }

    .transaction-list {
        height: auto;
        width: 100% !important;
        background-color: white;
        display: flex;
        position: relative;
        flex-direction: column;
        border-radius: 1rem;
        // border: 1px solid lightgray;
        box-shadow: 0 0 5px rgb(172, 172, 172);
        margin-bottom: 1rem;

        .header-list {
            display: flex;
            // height: 2.5rem;
            align-items: center;
            margin: 0 1rem 0 1rem;
            padding-block: 0.4rem;
            border-bottom: 1px solid lightgray;

            .left-header {
                width: 80%;
                display: flex;
                height: 2.5rem;
                align-items: center;

                .status-order {
                    background-color: white !important;
                    color: $primary2-color;
                    border: 1px solid $primary2-color;
                    font-size: 0.75rem;
                    font-weight: 600;
                    margin-right: 1rem;
                    padding-block: 0.4rem;

                    &.purple {
                        color: #8115D6;
                        border: none;
                        background-color: rgba(129, 21, 214, 0.2) !important;
                    }

                    &.blue {
                        color: #129AE7;
                        border: none;
                        background-color: rgba(18, 153, 231, 0.2) !important;
                    }

                    &.green {
                        color: #15D64B;
                        border: none;
                        background-color: rgba(21, 214, 76, 0.2) !important;
                    }

                    &.red {
                        color: #ED2013;
                        border: none;
                        background-color: rgba(237, 33, 19, 0.2) !important;
                    }
                }

                p {
                    font-size: 0.8rem;
                    margin-top: 1rem;
                    margin-right: 1rem;
                }
            }

            .right-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 20%;
                height: 2.5rem;

                .status-paid {
                    background-color: $primary2-color !important;
                    font-weight: 600;
                }
            }
        }

        .main-list {
            display: flex;
            padding: 1rem;
            align-items: center;

            .icon-service {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 4rem;
                }
            }

            .detail-service {
                margin-left: .7rem;

                .title-service {
                    font-weight: 600;
                    font-size: 1rem;
                    width: 20rem;
                    margin: 0;
                }

                .label-service {
                    font-size: 0.8rem;
                    margin: 0;
                }
            }

            .cost-order {
                width: 45.5rem;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: center;

                .nominal-order {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 0.1;
                }

                .title-cost {
                    font-size: 0.8rem;
                    line-height: 0.8;
                }
            }

        }

        .footer-list {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 1rem;

            .pay {
                width: 4rem;
                height: 1.8rem;
                display: flex;
                border-style: none;
                background-color: #1f83bb;
                color: white;
                font-size: 0.8rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                margin-right: 1rem;
                font-weight: 600;
            }

            .pay:hover {
                background-color: #266d96;
            }

            .repeat {
                width: 8rem;
                height: 1.8rem;
                display: flex;
                font-weight: 600;
                border-style: none;
                background-color: $primary2-color;
                color: white;
                font-size: 0.8rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                margin-right: 1rem;
            }

            .repeat:hover {
                background-color: $primary3-color;
            }

            .detail {
                width: 8rem;
                height: 1.8rem;
                display: flex;
                font-weight: 600;
                border-style: none;
                background-color: #f1f5fb;
                color: #1f83bb;
                font-size: 0.8rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                margin-right: 1rem;
            }

            .detail:hover {
                background-color: white;
            }

            .cancel {
                width: 9rem;
                height: 1.8rem;
                display: flex;
                font-weight: 600;
                border-style: none;
                background: none;
                color: red;
                font-size: 0.8rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                margin-right: 1rem;
                gap: 0.5rem;
            }

            .cancel:hover {
                background-color: aliceblue;
            }

            .destroy {
                width: 6rem;
                height: 1.8rem;
                display: flex;
                font-weight: 600;
                border-style: none;
                background: none;
                color: red;
                font-size: 0.8rem;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                margin-right: 1rem;
            }

            .destroy:hover {
                background-color: aliceblue;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-container {
        .transaction-list {
            .header-list {
                .left-header {
                    width: 100%;

                    .status-order {
                        font-size: 0.6rem;
                    }

                    p {
                        font-size: 0.6rem;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .right-header {
                    .status-paid {
                        font-size: 0.6rem;
                    }
                }
            }

            .main-list {
                width: 100%;
                justify-content: space-between;

                .icon-service {
                    width: 10%;
                }

                .detail-service {
                    width: 50%;

                    .title-service {
                        width: 10rem;
                        font-size: 0.875rem;
                    }

                    .label-service {
                        font-size: 0.75rem;
                    }
                }

                .cost-order {
                    .nominal-order {
                        font-size: 0.875rem;
                    }

                    .title-cost {
                        font-size: 0.75rem;
                    }
                }
            }

            .footer-list {
                justify-content: center;
                width: 100%;

                .detail {
                    width: 10rem;
                }

                .cancel {
                    width: 10rem;
                }
            }
        }
    }
}