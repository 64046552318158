.calendarWrap {
    width: 100%;
    height: 100%;
}

.inputBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border: 0.1rem solid lightgrey;
    border-radius: 0.8rem;
    color: grey;
    text-align: center;
}
.calendarElement{
    position: absolute;
    z-index: 5;
}

@media only screen and (max-width: 767px) {
    .inputBox {
        width: 100%;
    }
}