@import '../variable/color';

.overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  
    .main-content-cancel {
      height: 10rem;
      width: 25rem;
      background-color: white;
      border-radius: 1.5rem;
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .title-cancel {
        font-weight: 700;
        margin-bottom: 0.5rem ;
      }
      .warning-cancel{
          margin: 0;
      }
    }
    .btn-container-cancel {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      width: 100%;
      padding-inline: 2rem;
      .close-btn {
        height: 2.5rem;
        width: 48%;
        border-style: none;
        border-radius: 1rem;
        font-weight: 600;
        background-color: $primary2-color;
        color: white;
      }
      .close-btn:hover {
        opacity: 0.5;
      }
      .btn-save {
        height: 2.5rem;
        width: 48%;
        border-style: none;
        border-radius: 1rem;
        color: white;
        font-weight: 600;
        color: $primary2-color;
      }
      .btn-save:hover {
        opacity: 0.5;
      }
    }
  }
  