@import '../../variable/color';

.list-data-corporate {
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: $bg-grey;
  border: 1px solid $dark-grey;

  .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $dark-grey;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    .title {
      display: flex;
      align-items: center;

      h3 {
        font-size: 0.875rem;
        font-weight: 600;
        margin: 0;
        padding-right: 0.2rem;
      }


      h5 {
        font-weight: 600;
        border-left: 2px solid $black;
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
        padding-left: 0.2rem;
      }
    }

    h6 {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .list-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5rem;

    .content-data {
      font-size: 0.75rem;
      font-weight: 600;

      table {
        tr {
          td {
            padding-right: 3rem;
          }
        }
      }
    }

    .btn-detail {
      border-radius: 0.3rem;
      background-color: #e0e0e0;
      color: #949494;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}