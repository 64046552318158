@import '../../styles/variable/color';

.tab-menu {
  .btn-tab {
    border: none;
    color: $dark-grey1;
    font-size: 0.875rem;
    border-radius: 0;
    padding: 0.8rem 4rem;

    &:first-child {
      border-radius: 2rem 0 0 0;
    }

    &.active {
      font-weight: 700;
      border-bottom: 1px solid $primary2-color;
      color: $primary2-color;
      background-color: white;
    }

    &:hover {
      background-color: rgb(246, 246, 246);
      // color: $dark-grey1;
    }
  }
}