@import '../variable/_color.scss';

.draft-icon {
  color: #1f83bb;
  cursor: pointer;
}
.draft-icon:hover {
  color: #3689b9;
}

.draft-modal-container{
  width: 25rem;
  height: 70vh;
  background-color: white;
  position: absolute;
  right: 3rem;
  top: 5.1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 2rem;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  .modal-header{
      height: 5rem;
      width: 21rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 0.1rem solid lightgray;
      margin-bottom: 1.5rem;
      .inner-icon{
          margin-right: 0.5rem;
      }
      p{
          font-weight: 700;
          margin-top: 1rem;
      }
  }
  .draft-container {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
      .list-draft-container{
          width: 21rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 0.1rem solid lightgray;
          margin-bottom: 0.5rem;
          border-radius: 1rem;
          cursor: pointer;
          .list-draft-header{
              display: flex;
              width: 20rem;
              height: 3rem;
              border-bottom: 0.1rem solid lightgray;
              .left{
                  width: 10rem;
                  height: 3rem;
                  display: flex;
                  align-items: center;
                  .icon-symbol{
                    height: 2rem;
                    width: 2rem;
                    margin-right: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.2rem;
    
                  }
                  .title{
                      font-size: 0.75rem;
                      color: $dark-grey2;
                      margin: 0;
                  }
                  .desc{
                      font-weight: 700;
                      font-size: 0.75rem;
                      margin: 0;
                  }
              }
              .right{
                  width: 10rem;
                  height: 3rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-end;
                  margin-right: 0.5rem;
                  .title-cost{
                      font-size: 0.75rem;
                      color: $dark-grey2;
                      margin: 0;
                  }
                  .desc-cost{
                      font-size: 0.75rem;
                      font-weight: 700;
                      margin: 0;
                  }
              }
          }
          .list-draft-main{
              width: 20rem;
              height: 4.5rem;
              display: flex;
              align-items: center;
              .img-symbol{
                  border-radius: 0.3rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              .detail-order{
                  width: 70%;
                  height: 3.5rem;
                  margin-left: 0.5rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  .title-order{
                      margin: 0;
                      font-size: 0.875rem;
                      font-weight: 600;
                  }
                  .desc-order{
                      font-size: 0.75rem;
                      color: gray;
                      margin: 0;
                  }
              }
              
          }
      }
      .list-draft-container:hover{
          background-color: aliceblue;
    
      }
  }
}
.draft-modal-container::-webkit-scrollbar{
display: none;
}

@media only screen and (max-width: 767px) {
    .draft-modal-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 0 0 0 0;
        .modal-header {
            .close-modal {
                position: absolute;
                right: 2.5rem;
                font-size: 1.5rem;
            }
        }
    }
}